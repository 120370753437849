import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { validateUserCode, validateUserEmailAndSendCode } from "services/authRequests"
import { loginByEmailCode } from "services/store"
import { AuthModalsEnum, useAuthContext } from "../AuthContext"
import useEmailAuthForm, { EmailAuthData, EmailAuthStepStages } from "./email/useEmailForm"
import useAuthGuardContext from "web-marketplace/contexts/authGuard/useAuthGuardContext"

type SubmitHandlerCallbacks = {
  [key in keyof typeof EmailAuthStepStages]: () => Promise<void>
}

const useEmailAuth = ({
  setIsAuthModalOpen,
  setIsUserLoggedIn,
  networkMap,
  setCurrentAuthStageShowing,
  currentAuthStageShowing
}) => {
  const dispatch = useAppDispatch()
  const { isFirebaseAuthLoading, isMetamaskAuthLoading } = useAuthContext()
  const { isAuthGuardShieldEnabled } = useAuthGuardContext()
  const { companyWhitelabel, whitelabel } = useAppSelector((state) => state.whitelabelConfig)
  const [emailAuthStep, setEmailAuthStep] = useState<number>(1) // 0: initial, 1: email input, 2: email sent, 3: email verified
  const { JWTLoading, JWT, authIsExclusive, userAccessPermissionIsCompleted } = useAppSelector((state) => state.auth)

  const submitHandler = async (formData: EmailAuthData) => {
    const callbacks: SubmitHandlerCallbacks = {
      [EmailAuthStepStages.email_form]: async () => {
        await processUserEmail()
      },
      [EmailAuthStepStages.otp_verification]: async () => {
        await handleEmailLogin()
      },
      [EmailAuthStepStages.verified]: async () => {}
    }

    const callbackMethod = callbacks[formData?.stepStage]
    if (callbackMethod) {
      await callbackMethod()
    }
  }

  const { extraInputChangeHandler, isEmailAuthLoading, resetFields, manageCallbackErrors, ...emailForm } =
    useEmailAuthForm({ submitHandler })
  const { handleInputChange, formData } = emailForm
  const { email, otpCode } = formData

  useEffect(() => {
    resetFields()
  }, [isFirebaseAuthLoading, isMetamaskAuthLoading])

  const navigateEmailStep = () => {
    resetFields()
    setEmailAuthStep(1)
  }

  const navigateEmailCodeStep = () => {
    setCurrentAuthStageShowing(AuthModalsEnum.OTP_VERIFICATION)
  }

  const resetEmailStep = () => {
    resetFields()
    setEmailAuthStep(1)
    setCurrentAuthStageShowing(AuthModalsEnum.LOGIN)
  }

  const changeHandleSubmitStep = ({ stepValue }: { stepValue: EmailAuthStepStages }) => {
    emailForm?.handleInputChange({ target: { name: "stepStage", value: stepValue } })
  }

  const processUserEmail = async () => {
    let company = companyWhitelabel?.companyId || whitelabel?.companyId
    const isCustomDomain = localStorage.getItem("isCustomDomain")
    if (isCustomDomain === "true") {
      company = localStorage.getItem("companyId")
    }
    if (!company) {
      company = ""
    }
    const network = process.env.REACT_APP_ENVIRONMENT === "production" ? networkMap["137"] : networkMap["80001"]
    try {
      await validateUserEmailAndSendCode({ email }, network, company)
      navigateEmailCodeStep()
      changeHandleSubmitStep({ stepValue: EmailAuthStepStages.otp_verification })
    } catch (err) {
      console.log(err)
      manageCallbackErrors({ name: "email", value: "Email_Not_Found" })
    }
  }

  const handleLoginByEmailCode = async (result) => {
    dispatch(
      loginByEmailCode({
        ...result?.data
      })
    )
  }

  const handleEmailLogin = async () => {
    try {
      const result = await validateUserCode({ email, code: otpCode })

      handleLoginByEmailCode(result)
    } catch (err) {
      console.log(err)
      const mappedStatusCodeErrors = {
        410: () => {
          handleInputChange({ target: { name: "otpCode", value: "" } })

          return { message: "Email_OTP_Expired_Code" }
        },
        429: () => {
          resetEmailStep()

          return { message: "Email_OTP_Too_Many_Attempts" }
        },
        401: () => {
          return { message: "Email_OTP_Invalid_Code" }
        },
        400: () => {
          resetEmailStep()

          return { message: "Email_OTP_Invalid_Email" }
        }
      }

      const errorStatusCode = err?.response?.status
      const errorCallback = mappedStatusCodeErrors[errorStatusCode]

      let messageError = "Email_OTP_Generic_Error"

      if (errorCallback) {
        const { message } = errorCallback()
        messageError = message
      }

      emailForm?.setErrors({ otpCode: messageError } as EmailAuthData)
    }
  }

  const handleMakeLogin = () => {
    console.log("Realizei o Handler")
    resetEmailStep()
    setIsAuthModalOpen(false)
    setIsUserLoggedIn(true)
  }

  console.log("userAccessPermissionIsCompleted", userAccessPermissionIsCompleted)

  useEffect(() => {
    console.log(authIsExclusive, "authIsExclusive")
    console.log(userAccessPermissionIsCompleted, "userAccessPermissionIsCompleted")
    if (!JWTLoading && JWT && currentAuthStageShowing === AuthModalsEnum.OTP_VERIFICATION) {
      if (authIsExclusive) {
        if (userAccessPermissionIsCompleted) {
          console.log("Fiz pelo metodo exclusivo")
          handleMakeLogin()
        }
      } else {
        handleMakeLogin()
      }
    }
  }, [JWTLoading, JWT, authIsExclusive, userAccessPermissionIsCompleted])

  return {
    handleEmailLogin,
    navigateEmailCodeStep,
    navigateEmailStep,
    processUserEmail,
    isEmailAuthLoading,
    emailAuthStep,
    resetEmailStep,
    emailForm
  }
}

export default useEmailAuth
