import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

// ** Styles
import "@styles/base/pages/page-misc.scss"
import ServiceUnavailableImg from "@src/assets/images/errors/503_Error.png"

const ErrorServiceUnavailablePage = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="misc-wrapper">
        <Row
          className="d-flex flex-column flex-lg-row w-100"
          style={{
            maxWidth: "950px"
          }}
        >
          <Col sm={12} lg={6} className=" text-center">
            <img className="img-fluid" style={{ width: "100%", maxWidth: "450px" }} src={ServiceUnavailableImg} alt="Page Unavailable" />
          </Col>
          <Col sm={12} lg={6} className=" d-flex justify-content-center align-items-center">
            <div className="text-start p-1 p-md-0">
              <h1 className="mb-1">{t("Service_Unavailable")} </h1>
              <h4 className="text-wrap d-block d-md-none">{t("Service_Unavailable_Description_1")}</h4>
              <h4 className="text-nowrap d-none d-md-block">{t("Service_Unavailable_Description_1")}</h4>
              <h4>{t("Service_Unavailable_Description_2")}</h4>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ErrorServiceUnavailablePage
