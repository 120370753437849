import axios from "axios"

const axiosBase = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const getAxiosConfig = (JwtAccessToken: string) => {
  return {
    headers: {
      Authorization: `Bearer ${JwtAccessToken}`
    }
  }
}

export { axiosBase, getAxiosConfig }
