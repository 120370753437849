import { useTranslation } from "react-i18next"
import OtpInputRow from "./components/OtpInputRow"
import OtpSubmitButton from "./components/otpSubmitButton"
import useEmailAuthContext from "contexts/auth/methods/email/useEmailAuthContext"

const LoginOtpStageBody = () => {
  const { t } = useTranslation()
  const { handleSubmit } = useEmailAuthContext()
  const { formData } = useEmailAuthContext()

  return (
    <>
      <form onSubmit={handleSubmit} className="login-wrapper mx-auto justify-content-between gap-1">
        <div className="d-flex flex-column gap-75 h-100 justify-content-center">
          <div className="d-flex flex-column gap-25">
            <p className="m-0 text-center">{t("OTP_Subtitle_1")}</p>
            <p className="m-0 text-center fs-6">
              {t("OTP_Subtitle_2")} <b>{formData?.email}</b>
            </p>
          </div>
          <OtpInputRow />
          {/* 
          // No futuro adicionar a opção de reenviar o código
          <div className="d-flex align-items-center justify-content-center  gap-1">
            {t("Resend_Code_Question")} <span className="text-primary fw-bolder" onClick={() => console.log("hi")}>{t("Resend")}</span>
          </div> */}
        </div>
        <OtpSubmitButton />
      </form>
    </>
  )
}

export default LoginOtpStageBody
