export enum UserIdentityDocumentsEnum {
  CNPJ = "cnpj",
  CPF = "cpf"
}

export interface UserIdentityDocument {
  document: UserIdentityDocumentsEnum
  value: string
}

export interface UserKyc {
  fullName: string
  phone: string
  email: string
  identityDocument: UserIdentityDocument
  birthDate?: string
}
