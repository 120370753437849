import ButtonHandler from "@core/components/login/components/buttons/buttonHandler"
import useFormContext from "utility/context/formContext/useFormContext"

interface KycBasicInfoFormButtonHandlerProps {
  isLoading?: boolean
}

const KycBasicInfoFormButtonHandler = ({isLoading} : KycBasicInfoFormButtonHandlerProps) => {
  const { isFormLoading } = useFormContext()

  const isButtonLoading = isLoading || isFormLoading

  return (
    <>
      <ButtonHandler id="buttonHandler-Send" type="submit" text={"Send"} disabled={isButtonLoading} />
    </>
  )
}

export default KycBasicInfoFormButtonHandler