const useAuthNetwork = () => {
  const networkMap = {
    1: "ethereum_mainnet",
    5: "ethereum_goerli",
    137: "polygon_mainnet",
    80001: "polygon_mumbai"
  }

  const expectedChain = process.env.REACT_APP_ENVIRONMENT === "production" ? 137 : 80001
  const expectedChainName = expectedChain === 137 ? "Polygon Mainnet" : "Polygon Mumbai"

  return {
    networkMap,
    expectedChain,
    expectedChainName
  }
}

export default useAuthNetwork
