import { Dispatch, SetStateAction, createContext } from "react"

export enum MetaMaskStatusEnum {
  Connect_Pending = "1.1",
  Connect_Dismissed = "1.2",
  Connect_Failed = "1.3",
  Connect_Stack = "1.4",
  Connect_Success = "1.5",
  SwitchNetwork_Pending = "2.1",
  SwitchNetwork_Dismissed = "2.2",
  SwitchNetwork_Failed = "2.3",
  SwitchNetwork_Stack = "2.4",
  SwitchNetwork_Success = "2.5",
  SignInMessage_Pending = "3.1",
  SignInMessage_Dismissed = "3.2",
  SignInMessage_Failed = "3.3",
  SignInMessage_Stack = "3.4",
  SignInMessage_Success = "3.5",
  Connected = "5"
}

// export type MetaMaskAuthState = {}
export type MetaMaskAuthLoadingState = {
  isMetaMaskAuthLoading: boolean
  setIsMetaMaskAuthLoading: Dispatch<SetStateAction<boolean>>
}
export type MetaMaskAuthStatusState = {
  metaMaskAuthStatus: MetaMaskStatusEnum
  setMetaMaskAuthStatus: Dispatch<SetStateAction<MetaMaskStatusEnum>>
}
export type MetaMaskLoginCallbackFrom = "connect" | "switch" | "signMessage"
export type HandleMetaMaskLogin = (proceedFrom?: MetaMaskLoginCallbackFrom) => Promise<void | Window>

export type MetaMaskAuthProviderValues = {
  resetMetaMaskAuth: () => void
  handleMetaMaskLogin: HandleMetaMaskLogin
} & MetaMaskAuthLoadingState &
  MetaMaskAuthStatusState

const MetaMaskAuthContext = createContext({} as MetaMaskAuthProviderValues)

export default MetaMaskAuthContext
