import { axiosBase } from "services/axiosBase"

export async function fetchCompanyCustomDomain() {
  const hostname = window.location.hostname
  return await axiosBase.get(`companies/getId/findByCustomDomain?customDomain=${hostname}`)
}

export async function fetchDefaultMarketplace(id: string) {
  return await axiosBase.get(`companies/${id}/default-marketplace`)
}

export async function fetchCustomManifest(id: string) {
  return await axiosBase.get(`companies/${id}/custom-manifest`)
}
