import { useAppSelector } from "redux/hooks"
import LoginPage from "views/login/index"
import WeispaceLoginSwiperBanners from "web-weispace/views/login/components/swiper/banners"

const AuthGuardPage = () => {
  const { whiteLabelAuth } = useAppSelector((state) => state.whitelabelConfig)

  const swiperData: { banner: keyof typeof WeispaceLoginSwiperBanners }[] = Object.keys(WeispaceLoginSwiperBanners).map(
    (key: keyof typeof WeispaceLoginSwiperBanners) => ({
      banner: key
    })
  )

  return (
    <LoginPage
      loginHeaderLogo={whiteLabelAuth?.loginLogo}
      swiperData={swiperData}
      hideSwiper={true}
      whitelabelAuth={{
        ...whiteLabelAuth?.auth,
        methods: {}
      }}
    />
  )
}

export default AuthGuardPage
