import {
  SignInResponse,
  SignInResponseData,
  firebaseAppleSignIn,
  firebaseFacebookSignIn,
  firebaseGoogleSignIn,
  firebaseMicrosoftSignIn
} from "services/firebase"
import { AuthMethods, FirebaseSocialNetworkAuthEnum } from "../AuthContext"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { fetchJWTApple, fetchJWTFacebook, fetchJWTGoogle, fetchJWTMicrosoft } from "services/store"
import { useAppDispatch, useAppSelector } from "redux/hooks"

interface FirebaseAuthResult extends SignInResponseData {
  socialNetwork: FirebaseSocialNetworkAuthEnum
  setMethodInProgress: Dispatch<SetStateAction<AuthMethods>>
}

const useFirebaseAuth = ({ setIsAuthModalOpen, networkMap, setIsUserLoggedIn, setMethodInProgress }) => {
  const dispatch = useAppDispatch()

  const [firebaseAuthResult, setFirebaseAuthResult] = useState<FirebaseAuthResult>(null)
  const [isFirebaseAuthLoading, setIsFirebaseAuthLoading] = useState(false)

  const authRequestsMap: Record<
    FirebaseSocialNetworkAuthEnum,
    {
      request: () => Promise<SignInResponse>
      fetch: (fetchObject: { network: string } & SignInResponseData) => any
    }
  > = {
    [FirebaseSocialNetworkAuthEnum.GOOGLE]: {
      request: async () => await firebaseGoogleSignIn(),
      fetch: (fetchObject) => dispatch(fetchJWTGoogle(fetchObject))
    },
    [FirebaseSocialNetworkAuthEnum.FACEBOOK]: {
      request: async () => await firebaseFacebookSignIn(),
      fetch: (fetchObject) => dispatch(fetchJWTFacebook(fetchObject))
    },
    [FirebaseSocialNetworkAuthEnum.APPLE]: {
      request: async () => await firebaseAppleSignIn(),
      fetch: (fetchObject) => dispatch(fetchJWTApple(fetchObject))
    },
    [FirebaseSocialNetworkAuthEnum.MICROSOFT]: {
      request: async () => await firebaseMicrosoftSignIn(),
      fetch: (fetchObject) => dispatch(fetchJWTMicrosoft(fetchObject))
    }
  }

  const handleFirebaseAuth = async (socialNetwork: FirebaseSocialNetworkAuthEnum) => {
    setIsFirebaseAuthLoading(true)

    const authRequestResultMap = {
      error: () => {
        setIsFirebaseAuthLoading(false)
      },
      success: ({ socialNetwork, ...bodyData }: FirebaseAuthResult) => {
        setFirebaseAuthResult({ ...bodyData, socialNetwork })
        setIsFirebaseAuthLoading(false)
        setIsUserLoggedIn(true)
      }
    }

    const authRequest = authRequestsMap[socialNetwork]["request"]
    if (authRequest) {
      setMethodInProgress(socialNetwork.charAt(0).toUpperCase() + socialNetwork.slice(1))

      const { status, data: bodyData } = await authRequest()

      authRequestResultMap[status]({ ...bodyData, socialNetwork })

      setMethodInProgress(null)
    } else {
      throw new Error("Invalid firebase type")
    }
  }

  useEffect(() => {
    if (firebaseAuthResult) {
      const { socialNetwork, ...payload } = firebaseAuthResult
      const fetchObject = {
        ...payload,
        network: process.env.REACT_APP_ENVIRONMENT === "production" ? networkMap["137"] : networkMap["80001"]
      }

      const jwtFetch = authRequestsMap[socialNetwork]["fetch"]
      if (jwtFetch) {
        jwtFetch(fetchObject)
      } else {
        throw new Error("Invalid firebase type on jwt fetch")
      }
    }
  }, [firebaseAuthResult])

  return {
    handleFirebaseAuth,
    isFirebaseAuthLoading,
    setIsFirebaseAuthLoading
  }
}

export default useFirebaseAuth
