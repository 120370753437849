import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { axiosBase, getAxiosConfig } from "services/axiosBase"
import { cookies } from "utility/cookies"
import { getAnyTimeFromNow } from "utility/DateTimeUtils"

const currentParams = window.location.pathname.split("/")[1]
const defaultWeispaceRoute = "/weispace"
export const fetchJWTGoogle = createAsyncThunk("firebase/JWT/Google", async (body: object) => {
  try {
    const { data } = await axiosBase.post("/auth/google", body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchJWTFacebook = createAsyncThunk("firebase/JWT/Facebook", async (body: object) => {
  try {
    const { data } = await axiosBase.post("/auth/facebook", body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchJWTMicrosoft = createAsyncThunk("firebase/JWT/Microsoft", async (body: object) => {
  try {
    const { data } = await axiosBase.post("/auth/microsoft", body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchJWTApple = createAsyncThunk("firebase/JWT/Apple", async (body: object) => {
  try {
    const { data } = await axiosBase.post("/auth/apple", body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchJWTLoginByJWT = createAsyncThunk("login/JWT", async (jwt: string) => {
  try {
    const config = getAxiosConfig(jwt)
    const { data } = await axiosBase.post(`/login/jwt`, {}, config)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchAutoLogin = createAsyncThunk("auth/jwt", async (config: object) => {
  try {
    const { data } = await axiosBase.post("/login/jwt", {}, config)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const loginByEmailCode = createAsyncThunk("auth/email/confirm", async (payload: any) => {
  return payload
})

export const checkUserAccessPermission = createAsyncThunk(
  "auth/checkUserAccessPermission",
  async ({
    accessToken,
    collectionSlug,
    userEmail
  }: {
    accessToken: string
    collectionSlug: string
    userEmail: string
  }) => {
    try {
      const config = getAxiosConfig(accessToken)
      const { data } = await axiosBase.get(`collections/${collectionSlug}/whitelist-member/${userEmail}`, config)
      return data
    } catch (error) {
      throw new Error(error.response.data)
    }
  }
)

const session = () => {
  const userData = cookies.get("userData")
  if (userData && userData.type !== "Metamask") {
    return userData
  }
  return null
}
export const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    JWT: session() || null,
    JWTError: null,
    JWTLoading: false,
    shouldUserAddAnotherSocialEmail:
      !!session()?.user?.email?.includes("privaterelay.appleid.com") || !session()?.user?.email,
    companyNeedKyc: true,
    shouldUserNeedToDoTheKyc: false,
    userData: null,

    authRequiresKyc: false,
    authIsExclusive: false,
    shouldUserDoKyc: null,
    userKycStatus: null,

    userHaveAccessPermission: false,
    userAccessPermissionIsLoading: false,
    userAccessPermissionIsCompleted: false,
    userAccessPermissionErrorMessage: undefined
  },
  reducers: {
    setShouldUserAddAnotherSocialEmail: (state, { payload }) => {
      state.shouldUserAddAnotherSocialEmail = payload
    },
    setAuthRequiresKyc: (state, { payload }) => {
      state.authRequiresKyc = payload
    },
    setAuthIsExclusive: (state, { payload }) => {
      state.authIsExclusive = payload
    },
    setShouldUserDoKyc: (state, { payload }) => {
      state.shouldUserDoKyc = payload
    },
    setUserAccessPermission: (state, { payload }) => {
      state.userHaveAccessPermission = payload
    },
    resetUserKycAndPermissionProcess: (state) => {
      state.shouldUserDoKyc = null
      state.userKycStatus = null
      state.userHaveAccessPermission = false
    },
    removeSession: (state, action) => {
      state.JWTLoading = false
      state.JWT = null
      console.log("Removi pelo método padrão 2.0")
      cookies.remove("userData", { path: "/" })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJWTGoogle.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTGoogle.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload

        cookies.set("userData", { ...payload, expires: new Date(getAnyTimeFromNow()), type: "Google" }, { path: "/" })
        if (
          !payload?.user?.email ||
          (payload?.user?.email && payload.user.email.includes("privaterelay.appleid.com"))
        ) {
          state.shouldUserAddAnotherSocialEmail = true
        } else {
          state.shouldUserAddAnotherSocialEmail = false
        }
        if (`/${currentParams}` === defaultWeispaceRoute) {
          window.location.reload()
        }
      })
      .addCase(fetchJWTGoogle.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchJWTFacebook.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTFacebook.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        cookies.set("userData", { ...payload, expires: new Date(getAnyTimeFromNow()), type: "Facebook" }, { path: "/" })
        if (
          !payload?.user?.email ||
          (payload?.user?.email && payload.user.email.includes("privaterelay.appleid.com"))
        ) {
          state.shouldUserAddAnotherSocialEmail = true
        } else {
          state.shouldUserAddAnotherSocialEmail = false
        }
        if (`/${currentParams}` === defaultWeispaceRoute) {
          window.location.reload()
        }
      })
      .addCase(fetchJWTFacebook.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchJWTMicrosoft.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTMicrosoft.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        cookies.set(
          "userData",
          { ...payload, expires: new Date(getAnyTimeFromNow()), type: "Microsoft" },
          { path: "/" }
        )
        if (
          !payload?.user?.email ||
          (payload?.user?.email && payload.user.email.includes("privaterelay.appleid.com"))
        ) {
          state.shouldUserAddAnotherSocialEmail = true
        } else {
          state.shouldUserAddAnotherSocialEmail = false
        }
        if (`/${currentParams}` === defaultWeispaceRoute) {
          window.location.reload()
        }
      })
      .addCase(fetchJWTMicrosoft.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchJWTApple.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTApple.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        cookies.set("userData", { ...payload, expires: new Date(getAnyTimeFromNow()), type: "Apple" }, { path: "/" })
        if (
          !payload?.user?.email ||
          (payload?.user?.email && payload.user.email.includes("privaterelay.appleid.com"))
        ) {
          state.shouldUserAddAnotherSocialEmail = true
        } else {
          state.shouldUserAddAnotherSocialEmail = false
        }
        if (`/${currentParams}` === defaultWeispaceRoute) {
          window.location.reload()
        }
      })
      .addCase(fetchJWTApple.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchJWTLoginByJWT.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTLoginByJWT.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        cookies.set("userData", { ...payload, expires: new Date(getAnyTimeFromNow()), type: "JWT" }, { path: "/" })
        if (
          !payload?.user?.email ||
          (payload?.user?.email && payload.user.email.includes("privaterelay.appleid.com"))
        ) {
          state.shouldUserAddAnotherSocialEmail = true
        } else {
          state.shouldUserAddAnotherSocialEmail = false
        }
      })
      .addCase(fetchJWTLoginByJWT.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })
      .addCase(fetchAutoLogin.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchAutoLogin.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        cookies.set(
          "userData",
          { ...payload, expires: new Date(getAnyTimeFromNow()), type: "auto-login" },
          { path: "/" }
        )
        if (
          !payload?.user?.email ||
          (payload?.user?.email && payload.user.email.includes("privaterelay.appleid.com"))
        ) {
          state.shouldUserAddAnotherSocialEmail = true
        } else {
          state.shouldUserAddAnotherSocialEmail = false
        }
      })
      .addCase(fetchAutoLogin.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })
      .addCase(loginByEmailCode.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(loginByEmailCode.fulfilled, (state, { payload }) => {
        state.JWT = payload
        console.log("Setei el cookie")
        cookies.set("userData", { ...payload, expires: new Date(getAnyTimeFromNow()), type: "email" }, { path: "/" })
        if (`/${currentParams}` === defaultWeispaceRoute) {
          window.location.reload()
        }
        if (
          !payload?.user?.email ||
          (payload?.user?.email && payload.user.email.includes("privaterelay.appleid.com"))
        ) {
          state.shouldUserAddAnotherSocialEmail = true
        } else {
          state.shouldUserAddAnotherSocialEmail = false
        }

        //TODO Se collection for authIsExclusive === true
        //TODO Deve fazer uma nova requisição passando o JWT.accessToken do usuário para saber se ele tem permissão de acesso e checkar o status do KYC dele
        state.JWTLoading = false
      })
      .addCase(loginByEmailCode.rejected, (state, { payload }) => {
        state.JWTError = payload
        state.JWTLoading = false
      })
      .addCase(checkUserAccessPermission.pending, (state) => {
        state.userAccessPermissionIsLoading = true
        state.userAccessPermissionErrorMessage = undefined
        state.userAccessPermissionIsCompleted = false
      })
      .addCase(checkUserAccessPermission.fulfilled, (state, { payload }) => {
        if (state.authIsExclusive) {
          if (payload?.inviteOnly) {
            if (payload?.member) {
              state.userHaveAccessPermission = true
              const { KYCStatus } = payload?.member || false
              if (KYCStatus) {
                if (KYCStatus !== "approved") {
                  state.shouldUserDoKyc = true
                  state.userKycStatus = KYCStatus
                } else {
                  state.shouldUserDoKyc = false
                }
              }
            } else {
              state.userHaveAccessPermission = false
            }
          } else {
            state.userHaveAccessPermission = true
            state.shouldUserDoKyc = false
          }

          state.userAccessPermissionIsCompleted = true
        } else {
          state.userHaveAccessPermission = true
          state.shouldUserDoKyc = false
        }
        state.userAccessPermissionIsLoading = false
      })
      .addCase(checkUserAccessPermission.rejected, (state, { payload }) => {
        state.userHaveAccessPermission = false
        state.userAccessPermissionErrorMessage = payload
        state.userAccessPermissionIsLoading = false
        state.userAccessPermissionIsCompleted = false
      })
  }
})

export const {
  removeSession,
  setShouldUserAddAnotherSocialEmail,
  setAuthRequiresKyc,
  setAuthIsExclusive,
  setUserAccessPermission,
  setShouldUserDoKyc,
  resetUserKycAndPermissionProcess
} = authSlice.actions
export default authSlice.reducer
