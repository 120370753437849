import DisplaySource from "components/displaySource"
import { useTranslation } from "react-i18next"
import OtpImage from "assets/images/pages/Otp_Image.png"
import BackButtonHandler from "../../components/buttons/backButtonHandler"
import useEmailAuthContext from "contexts/auth/methods/email/useEmailAuthContext"

const LoginOtpStageHeader = () => {
  const { t } = useTranslation()

  const { resetEmailStep, formData } = useEmailAuthContext()

  return (
    <div className="login-wrapper h-100">
      <BackButtonHandler handleClickAction={resetEmailStep} />
      <h4 className="m-0 text-center fw-bolder">{t("OTP_Title")}</h4>
      <DisplaySource src={OtpImage} centralized contained size={{ maxWidth: "240px" }} />
    </div>
  )
}

export default LoginOtpStageHeader
