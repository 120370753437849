import { setShowLoginModal } from "@core/components/login-modal/store"
import { AuthModalsEnum } from "contexts/auth/AuthContext"
import useAuthContext from "contexts/auth/AuthInContext"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { removeSession, resetUserKycAndPermissionProcess, setShouldUserDoKyc } from "services/store"
import { removeSessionMetamask } from "services/store/metamask"
import { setActiveTokenMenu } from "web-marketplace/views/token/store"
import MarketPlaceAuthContext, { MarketPlaceAuthContextI } from "./marketplaceAuthContext"

interface MarketPlaceAuthProviderProps {
  children: React.ReactNode
}

const MarketPlaceAuthProvider = ({ children }: MarketPlaceAuthProviderProps) => {
  const { Provider } = MarketPlaceAuthContext

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { userHaveAccessPermission, shouldUserDoKyc, userKycStatus, authIsExclusive } = useAppSelector(
    (state) => state.auth
  )

  const { isUserLoggedIn, setIsUserLoggedIn, setCurrentAuthStageShowing, setIsAuthModalOpen, handleLoginSuccess } =
    useAuthContext()

  const handleAuthLogout = () => {
    dispatch(removeSession(null))
    dispatch(removeSessionMetamask(null))
    dispatch(resetUserKycAndPermissionProcess(null))
    setIsUserLoggedIn(false)
    dispatch(setActiveTokenMenu("information"))
    navigate(location.pathname, { replace: true })
  }

  useEffect(() => {
    console.log("MarketplaceProvider - Permission Effect - Start")
    if (!authIsExclusive && userHaveAccessPermission) {
      console.log("Login não é exclusivo e o usuário tem permissão. Pode logar normalmente")
    }
    if (!authIsExclusive && !userHaveAccessPermission) {
      console.log("Login não é exclusivo e o usuário não tem permissão. Pode logar normalmente")
    }
    if (authIsExclusive && userHaveAccessPermission) {
      console.log("Login é exclusivo e o usuário tem permissão. Pode logar normalmente")
    }
    if (authIsExclusive && !userHaveAccessPermission) {
      console.log("Login é exclusivo e o usuário não tem permissão. Mostrar tela de acesso negado")
    }
    console.log("MarketplaceProvider - Permission Effect - End")
  }, [authIsExclusive, userHaveAccessPermission])

  useEffect(() => {
    console.log("Fiz o Fluxo de Login", isUserLoggedIn)
    if (isUserLoggedIn) {
      if (!userHaveAccessPermission && authIsExclusive) {
        console.log("Changing to Denied")
        setCurrentAuthStageShowing(AuthModalsEnum.ACCESS_DENIED)
      } else if (shouldUserDoKyc || (!!userKycStatus && userKycStatus !== "approved")) {
        console.log("shouldUserDoKyc", shouldUserDoKyc, userKycStatus)
        setCurrentAuthStageShowing(AuthModalsEnum.KYC)
      } else {
        handleLoginSuccess()
        setIsAuthModalOpen(false)
        dispatch(setShowLoginModal(false))
      }
    }
  }, [isUserLoggedIn, userHaveAccessPermission, shouldUserDoKyc, userKycStatus])

  const providerValues: MarketPlaceAuthContextI = {
    handleAuthLogout
  }

  return <Provider value={providerValues}>{children}</Provider>
}

export default MarketPlaceAuthProvider
