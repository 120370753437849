import { lazy } from "react"

const LazyTransactions = lazy(() => import("./pages/transactions"))

const mainTransactionsPath = '/weispace/transactions'

export const transactionsPaths = {
  home: mainTransactionsPath
}

const transactionsRoutes = [
  {
    path: transactionsPaths.home,
    element: <LazyTransactions />
  }
]

export default transactionsRoutes
