import { Helmet } from "react-helmet-async"
import { useWeispaceAppSelector } from "redux/hooks"

const TitleProvider = () => {
  const { whitelabel, loading } = useWeispaceAppSelector((state) => state.whitelabelConfig)
  const siteTitle = whitelabel?.layout?.userSpace?.title
  const siteFavicon = whitelabel?.layout?.userSpace?.favicon
  return (
    <>
      {!loading && (
        <Helmet>
          <link rel="shortcut icon" href={siteFavicon} />
          <title>{siteTitle}</title>
        </Helmet>
      )}
    </>
  )
}

export default TitleProvider
