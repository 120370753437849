import { AuthMethods, useAuthContext } from "contexts/auth/AuthContext"
import MetamaskLogo from "assets/images/logo/metamask.png"
import MainAuthButton from "../../components/buttons/mainAuthButton"
import { Label } from "reactstrap"
import { useTranslation } from "react-i18next"
import useLoginContentContext from "@core/components/login/context/useLoginContentContext"
import useWeb3AuthContext from "contexts/auth/methods/web3/useWeb3AuthContext"
import { Web3AuthProvidersEnum } from "contexts/auth/methods/web3/web3AuthContext"
import classNames from "classnames"

interface AuthMethodsProps {
  name: AuthMethods
  enabled: boolean
  logo: string
  priority: number
  handleLogin: Function
}

const ExternalWallet = () => {
  const { t } = useTranslation()
  const { isMetamaskLoginEnabled, isAppleLoginEnabled, isFacebookLoginEnabled, isGoogleLoginEnabled, isMicrosoftLoginEnabled } = useLoginContentContext()
  const isSocialsEnabled = isAppleLoginEnabled || isFacebookLoginEnabled || isGoogleLoginEnabled || isMicrosoftLoginEnabled
  const { setSelectedWeb3AuthProvider, setIsWeb3AuthLoading } = useWeb3AuthContext()

  const externalWalletMethodProps: AuthMethodsProps[] = [
    {
      name: "Metamask",
      enabled: isMetamaskLoginEnabled,
      logo: MetamaskLogo,
      priority: 1,
      handleLogin: () => {
        setSelectedWeb3AuthProvider(Web3AuthProvidersEnum.METAMASK)
      }
    }
  ]

  const enabledAuthMethods = externalWalletMethodProps.filter((authMethod) => authMethod.enabled)

  if (enabledAuthMethods.length === 0) return null

  return (
    <div className={classNames("d-flex flex-column gap-50 w-100", {
      'justify-content-end h-100': !isSocialsEnabled
    })}>
      <Label className="fw-bolder ml-1" style={{ fontSize: 14 }}>
        {t("External_wallets")}
      </Label>
      <MainAuthButton
        authMethod={enabledAuthMethods[0]?.name}
        handleClickAction={enabledAuthMethods[0]?.handleLogin}
        src={enabledAuthMethods[0]?.logo}
      />
    </div>
  )
}

export default ExternalWallet
