import LoginContent, { LoginComponentProps } from "@core/components/login"
import { Card } from "reactstrap"
import useBreakpoint from "utility/hooks/useMatchBreakpoint"

interface LoginContentComponentProps extends LoginComponentProps {}

const LoginContentComponent = ({ ...loginContentProps }: LoginContentComponentProps) => {
  const match = useBreakpoint({ direction: "down", breakpoint: "md" })
  const ContainerTag = match ? "div" : Card

  return (
    <ContainerTag className="fullscreen-login-content">
      <LoginContent {...loginContentProps} />
    </ContainerTag>
  )
}

export default LoginContentComponent