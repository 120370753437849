import { useAppSelector } from "redux/hooks"
import KycHeaderLayout from "../../../header"
import { RiSendPlaneLine } from "react-icons/ri"
import KycCaption from "../../../components/kycCaption"
import ButtonHandler from "@core/components/login/components/buttons/buttonHandler"
import useMarketPlaceAuthContext from "web-marketplace/provider/auth/useMarketPlaceAuthContext"

const KycResultSendStageSlide = () => {
  const { userKycStatus } = useAppSelector((state) => state.auth)
  const { handleAuthLogout } = useMarketPlaceAuthContext()

  if (userKycStatus === "idle") {
    return (
      <>
        <KycHeaderLayout title="KYC_Result_Send" description="KYC_Result_Send_Description" src="" />
        <div className="login-wrapper d-flex flex-column gap-75 h-100"></div>
        <div className="login-wrapper gap-2">
          <ButtonHandler type="button" handleClickAction={() => handleAuthLogout()} text="Logout" />
          <KycCaption icon={RiSendPlaneLine} text="KYC_Result_Send_Caption" />
        </div>
      </>
    )
  }

  return <></>
}

export default KycResultSendStageSlide
