import classNames from "classnames"
import DisplaySourceSkeleton from "./displaySkeleton"
import DisplaySourceImage from "./displayImage"
import DisplaySourceVideo from "./displayVideo"
import DisplayEmptySource from "./displayEmptySource"
import { MediaType } from "utility/request-interfaces/collection-types"

export interface DisplaySourceTypeProps {
  src: string
  displayClassName?: string
  displayStyle?: React.CSSProperties
}

interface DisplayProportion {
  width: number
  height: number
}

export interface DisplaySourceProps extends DisplaySourceTypeProps {
  isLoading?: boolean
  containerClassName?: string
  containerStyle?: React.CSSProperties
  wrapperClassName?: string
  wrapperStyle?: React.CSSProperties
  sourceType?: MediaType
  centralized?: boolean
  contained?: boolean
  displayProportion?: DisplayProportion | string
  size?: {
    maxWidth?: string
    minWidth?: string
  }
}

/** 
  @param {"image" | "gif" | "video"} sourceType - The type of the source to be displayed
  @param {string} src - The source of the image
  @param {boolean} isLoading - If the image is loading
  @param {string} centralized - If the image need to be in center
  @param {string} size - The size of the image { maxWidth: string, minWidth: string}
  @param {string} containerClassName - The class name of the container
  @param {React.CSSProperties} containerStyle - The style of the container
  @param {boolean} contained - If the image need to be contained
  @param {DisplayProportion} displayProportion - String with % or DisplayProportion to calculate the proportion of the image
*/
const DisplaySource = ({
  containerClassName,
  containerStyle,
  wrapperClassName,
  wrapperStyle,
  isLoading,
  sourceType = "image",
  centralized = false,
  contained = false,
  displayClassName,
  displayProportion = "100%",
  size = {
    maxWidth: null,
    minWidth: null
  },
  ...displaySourceProps
}: DisplaySourceProps) => {
  const calculateDisplayProportion = () => {
    if (typeof displayProportion === "string" && displayProportion.includes("%")) {
      return displayProportion
    } else if (typeof displayProportion === "object") {
      return `${(displayProportion?.height / displayProportion?.width) * 100}%`
    } else {
      return "100%"
    }
  }

  return (
    <div
      className={classNames("display-source-container", {
        [containerClassName]: containerClassName,
        "mx-auto my-auto": centralized
      })}
      style={{
        ...containerStyle,
        maxWidth: size?.maxWidth,
        minWidth: size?.minWidth
      }}
    >
      <div
        className={classNames("display-source-wrapper", {
          [wrapperClassName]: wrapperClassName,
          "display-contained": contained
        })}
        style={{
          ...wrapperStyle,
          paddingBottom: calculateDisplayProportion()
        }}
      >
        {isLoading && <DisplaySourceSkeleton />}
        {!isLoading && !displaySourceProps?.src && <DisplayEmptySource />}
        {!isLoading && displaySourceProps?.src && (
          <>
            {sourceType === "image" && <DisplaySourceImage {...displaySourceProps} />}
            {(sourceType === "gif" || sourceType === "video") && <DisplaySourceVideo {...displaySourceProps} />}
          </>
        )}
      </div>
    </div>
  )
}

export default DisplaySource
