import { useAppSelector } from "redux/hooks"
import ErrorPageNotFoun from "views/error404"
import ErrorServiceUnavailablePage from "views/error503"
import useAccessGuardContext from "web-marketplace/contexts/accessGuard/useAccessGuardContext"
import AuthGuardPage from "web-marketplace/contexts/authGuard/guard"
import useAuthGuardContext from "web-marketplace/contexts/authGuard/useAuthGuardContext"
import useUnavailableContentContext from "web-marketplace/contexts/unavailableContentGuard/useUnavailableContentGuardContext"

export enum MarketPlaceGuardsEnum {
  AgeGate = "AgeGate",
  UnavailableContentGuard = "UnavailableContentGuard",
  AccessGuard = "AccessGuard"
}

interface MarketPlaceGuardMapProps {
  enabled: boolean
  Guard: JSX.Element
}

type MarketPlaceGuardsMap = {
  [key in MarketPlaceGuardsEnum]: MarketPlaceGuardMapProps
}

interface MarketPlaceGuardGateProps {
  children: React.ReactNode
}

const MarketPlaceGuardGate = ({ children }: MarketPlaceGuardGateProps) => {
  const { whiteLabelGuardError } = useAppSelector((state) => state.whitelabelConfig)
  const { isAuthGuardShieldEnabled } = useAuthGuardContext()
  const { isAccessGuardShieldEnabled } = useAccessGuardContext()
  const { isUnavailableContentShieldEnabled } = useUnavailableContentContext()

  const guardsMap: MarketPlaceGuardsMap = {
    [MarketPlaceGuardsEnum.AccessGuard]: {
      enabled: isAccessGuardShieldEnabled || isAuthGuardShieldEnabled,
      Guard: <AuthGuardPage />
    },
    [MarketPlaceGuardsEnum.AgeGate]: {
      enabled: false,
      Guard: <></>
    },
    [MarketPlaceGuardsEnum.UnavailableContentGuard]: {
      enabled: isUnavailableContentShieldEnabled,
      Guard: <ErrorServiceUnavailablePage />
    }
  }

  const priorityGuard = guardsMap?.[MarketPlaceGuardsEnum.UnavailableContentGuard]
  if (priorityGuard?.enabled) {
    return <>{priorityGuard.Guard}</>
  }

  if (whiteLabelGuardError) {
    return <ErrorPageNotFoun />
  }

  const filteredGuards = Object.keys(guardsMap).filter(
    (guard) => guard !== MarketPlaceGuardsEnum.UnavailableContentGuard
  )

  for (const guardData of filteredGuards) {
    const guardGate: MarketPlaceGuardMapProps = guardsMap?.[guardData]
    if (guardGate) {
      const { enabled, Guard } = guardGate

      if (enabled) {
        return <>{Guard}</>
      }
    }
  }

  return <>{children}</>
}

export default MarketPlaceGuardGate
