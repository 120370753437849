import { connect } from "http2"
import { MetaMaskAuthStatusState, MetaMaskLoginCallbackFrom, MetaMaskStatusEnum } from "../metamaskAuthContext"
import handleMetaMaskError, { MetamaskErrors } from "./handleError"
import useAuthContext from "contexts/auth/AuthInContext"
import useWagmi from "contexts/auth/hooks/useWagmi"

interface WagmiI extends Pick<ReturnType<typeof useWagmi>, "account" | "connect" | "network"> {}

interface ConnectMetaMaskProps extends Pick<MetaMaskAuthStatusState, "setMetaMaskAuthStatus">, WagmiI {
  proceedFrom: MetaMaskLoginCallbackFrom
}

/**
 * Connects the user to the metamask wallet
 * @param proceedFrom - The callback from which the user is trying to connect
 * @param setMetaMaskAuthStatus - The function to set the metamask auth status
 * @returns {void}
 */

const connectMetaMask = async ({
  proceedFrom,
  network: { chain },
  connect: { connectAsync },
  setMetaMaskAuthStatus,
  account: { isConnected }
}: ConnectMetaMaskProps) => {
  const {
    Connect_Pending: Pending,
    Connect_Success: Success,
    Connect_Dismissed: Dismissed,
    Connect_Failed: Failed,
    Connect_Stack: Stack
  } = MetaMaskStatusEnum

  const mustTryConnection = proceedFrom === "connect" || (!proceedFrom && !chain) || (!chain && !isConnected)

  if (mustTryConnection) {
    setMetaMaskAuthStatus(Pending)

    const response = {
      userData: null,
      stopProceed: false
    }

    try {
      const userData = await connectAsync()

      setMetaMaskAuthStatus(Success)

      response.userData = userData
      return response
    } catch (err) {
      const mappedMetaMaskConnectErrors = handleMetaMaskError(err?.name as MetamaskErrors)
      if (mappedMetaMaskConnectErrors) {
        const { callback, status } = mappedMetaMaskConnectErrors
        const authStatus = status === "dismiss" ? Dismissed : Stack

        callback(setMetaMaskAuthStatus, authStatus)
      } else {
        setMetaMaskAuthStatus(Failed)
      }

      response.stopProceed = true
      return response
    }
  }
  return null
}

export default connectMetaMask
