import { useForm } from "utility/hooks/useForm"
import useEmailAuthFormDataValidator from "./useEmailValidate"

export enum EmailAuthStepStages {
  email_form = "email_form",
  otp_verification = "otp_verification",
  verified = "verified"
}
export interface EmailAuthData {
  email: string
  otpCode: string
  stepStage: EmailAuthStepStages
}

interface ExtraInputChangeHandler {
  name: keyof EmailAuthData
  value: string | EmailAuthStepStages
}

interface EmailAuthFormProps {
  submitHandler: (formData: EmailAuthData) => Promise<void>
}

const useEmailAuthForm = ({ submitHandler }: EmailAuthFormProps) => {
  const emailAuthData: EmailAuthData = {
    email: "",
    otpCode: "",
    stepStage: EmailAuthStepStages.email_form
  }

  const { isFormLoading: isEmailAuthLoading, ...emailForm } = useForm({
    initialState: emailAuthData,
    submitCallback: (formData) => submitHandler(formData as EmailAuthData),
    validate: useEmailAuthFormDataValidator,
    failedSubmitCallback: null
  })

  const { handleInputChange, setErrors, setFormData, setIsFormLoading, setIsSubmit } = emailForm

  const extraInputChangeHandler = ({ name, value }: ExtraInputChangeHandler) => {
    handleInputChange({ target: { name, value } })
  }

  const resetFields = () => {
    setFormData(emailAuthData)
    setErrors({} as EmailAuthData)
    setIsFormLoading(false)
    setIsSubmit(false)
  }

  const manageCallbackErrors = ({ name, value }: ExtraInputChangeHandler) => {
    setErrors({ [name]: value } as any)
    setIsSubmit(true)
  }

  return {
    isEmailAuthLoading,
    ...emailForm,
    extraInputChangeHandler,
    resetFields,
    manageCallbackErrors
  }
}

export default useEmailAuthForm
