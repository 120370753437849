import FormProvider from "utility/context/formContext/formProvider"
import kycBasicInfosInitialValues from "./hooks/initialValues"
import useKycBasicInfosFormDataValidator from "./hooks/validate"
import useFormContext from "utility/context/formContext/useFormContext"
import KycEmailInput from "./components/content/email"
import KycNameInput from "./components/content/name"
import KycCpfInput from "./components/content/cpf"
import KycCnpjInput from "./components/content/cnpj"
import KycPhoneInput from "./components/content/phone"
import { Col, Row } from "reactstrap"
import KycBirthDateInput from "./components/content/birth_date"
import { KycPersonalInfosI } from "../../../context/kycFormHooks/initialState"
import KycBasicInfosFormContainer from "./components/container"
import KycBasicInfoFormButtonHandler from "./components/buttonHandler"
import { useEffect } from "react"
import { UserIdentityDocumentsEnum } from "interfaces/user"
import useKycContext from "../../../context/useKycContext"

const KycBasicInfosForm = () => {
  const { setSubmissionCreationIsEnabled, submissionCreationIsEnabled } = useKycContext()
  const {
    setFormData: setKycFormData,
    formData: kycFormData,
    handleSubmit,
    isFormLoading: isMainFormLoading
  } = useFormContext()

  const isPFForm = kycFormData?.identityDocument?.document === UserIdentityDocumentsEnum.CPF
  const isPJForm = kycFormData?.identityDocument?.document === UserIdentityDocumentsEnum.CNPJ

  const handleSubmitCallback = async (formData: KycPersonalInfosI) => {
    setKycFormData((prev) => ({ ...prev, ...formData }))
    setSubmissionCreationIsEnabled(true)
  }

  useEffect(() => {
    const { fullName, identityDocument, phone, email } = kycFormData || {}

    if (
      submissionCreationIsEnabled &&
      fullName &&
      identityDocument?.value &&
      identityDocument?.document &&
      phone &&
      email
    ) {
      setSubmissionCreationIsEnabled(false)
      handleSubmit(null)
    }
  }, [submissionCreationIsEnabled, kycFormData])

  return (
    <FormProvider
      initialState={kycBasicInfosInitialValues({ kycFormData })}
      submitCallback={(formData) => handleSubmitCallback(formData as KycPersonalInfosI)}
      validate={useKycBasicInfosFormDataValidator}
      failedSubmitCallback={null}
      className="d-flex flex-column gap-1 justify-content-center h-100 login-wrapper"
    >
      <KycBasicInfosFormContainer mainKycForm={kycFormData} mainSubmitCallback={handleSubmit}>
        <div className="d-flex flex-column gap-50 h-100 ">
          <KycNameInput />
          <KycEmailInput />

          <Row className="login-kyc-row">
            <Col xs={12} sm={6}>
              {isPFForm && <KycCpfInput />}
              {isPJForm && <KycCnpjInput />}
            </Col>
            <Col xs={12} sm={6}>
              <KycPhoneInput />
            </Col>
          </Row>
          {isPFForm && <KycBirthDateInput />}
        </div>

        <KycBasicInfoFormButtonHandler isLoading={isMainFormLoading} />
      </KycBasicInfosFormContainer>
    </FormProvider>
  )
}

export default KycBasicInfosForm
