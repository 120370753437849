import { FormHookProps, useForm } from "utility/hooks/useForm"
import FormContext from "./formContext"
import classNames from "classnames"

interface FormContextProps<t> extends FormHookProps<t> {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  formRest?: { [key: string]: any }
}

function FormProvider<t>({ children, className, style, formRest, ...formHookProps }: FormContextProps<t>) {
  const { Provider } = FormContext

  const formMethods = useForm({ ...formHookProps })

  const providerValues = formMethods

  return (
    <>
      <Provider value={providerValues}>
          {children}
      </Provider>
    </>
  )
}

export default FormProvider
