import classNames from "classnames"

interface DisplaySourceSkeletonProps {
  containerClassName?: string
  placeholderClassName?: string
  containerStyle?: React.CSSProperties
  placeholderStyle?: React.CSSProperties
}

const DisplaySourceSkeleton = ({
  containerClassName,
  containerStyle,
  placeholderClassName,
  placeholderStyle
}: DisplaySourceSkeletonProps) => {
  return (
    <div
      className={classNames("placeholder-glow w-100 h-100 position-absolute rounded", {
        [containerClassName]: containerClassName
      })}
      style={containerStyle}
    >
      <span
        className={classNames("placeholder w-100 h-100", { [placeholderClassName]: placeholderClassName })}
        style={placeholderStyle}
      />
    </div>
  )
}

export default DisplaySourceSkeleton
