import Icon from "@core/components/icon"
import AuthButtonRoot from "./root"
import { IoChevronBackOutline } from "react-icons/io5"

interface BackButtonHandlerProps {
  handleClickAction: Function
  disabled?: boolean
}

const BackButtonHandler = ({ handleClickAction, disabled }: BackButtonHandlerProps) => {
  const { Container } = AuthButtonRoot

  return (
    <div className="login-backward-button">
      <Container handleClickAction={handleClickAction} className="p-25 rounded-2 bg-white" outline>
        <Icon icon={IoChevronBackOutline} size={"1.8rem"} style={{ color: "454545" }} />
      </Container>
    </div>
  )
}

export default BackButtonHandler
