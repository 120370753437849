import { useEffect, useState } from "react"
import AuthGuardContext, { AuthGuardContextI } from "./authGuardContext"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { setAuthRequiresKyc } from "services/store"
import useAuthContext from "contexts/auth/AuthInContext"

interface AuthGuardProviderProps {
  children: React.ReactNode
}

const AuthGuardProvider = ({ children }: AuthGuardProviderProps) => {
  const { Provider } = AuthGuardContext
  const { setIsUserLoggedIn } = useAuthContext()
  const dispatch = useAppDispatch()
  const { whiteLabelGuard, whiteLabelGuardIsLoading } = useAppSelector((state) => state.whitelabelConfig)
  const { shouldUserDoKyc, JWT } = useAppSelector((state) => state.auth)

  const [isAuthGuardShieldEnabled, setIsAuthGuardShieldEnabled] = useState<boolean>(whiteLabelGuard?.kyc?.enabled)

  useEffect(() => {
    if (whiteLabelGuard?.kyc?.enabled) {
      console.log("AuthGuardProvider => AuthGuard(KYC) is enabled")
      setIsAuthGuardShieldEnabled(whiteLabelGuard?.kyc?.enabled)
      dispatch(setAuthRequiresKyc(true))
      if (JWT?.accessToken && JWT?.type === "email") {
        setIsUserLoggedIn(true)
      }
    }
  }, [whiteLabelGuardIsLoading])

  useEffect(() => {
    if (shouldUserDoKyc !== null && shouldUserDoKyc !== undefined) {
      if (!shouldUserDoKyc) {
        console.log("AuthGuardProvider => AuthGuard(KYC) is disabled by user")
        setIsAuthGuardShieldEnabled(false)
      } else {
        console.log("AuthGuardProvider => AuthGuard(KYC) is enabled by user")
        setIsAuthGuardShieldEnabled(true)
      }
    }
  }, [shouldUserDoKyc])

  const providerValues: AuthGuardContextI = {
    isAuthGuardShieldEnabled,
    setIsAuthGuardShieldEnabled
  }

  return <Provider value={providerValues}>{children}</Provider>
}

export default AuthGuardProvider
