import { ButtonProps, Spinner } from "reactstrap"
import AuthButtonRoot from "./root"

interface ButtonHandlerProps extends ButtonProps {
  handleClickAction?: Function
  text: string
  isLoading?: boolean
  disabled?: boolean
}

const ButtonHandler = ({
  handleClickAction,
  text,
  isLoading = false,
  disabled = false,
  ...rest
}: ButtonHandlerProps) => {
  const { Container, Text } = AuthButtonRoot
  return (
    <Container
      handleClickAction={handleClickAction}
      className="p-75 px-2 rounded-3"
      isLoading={isLoading}
      disabled={disabled}
      {...rest}
    >
      {!isLoading && <Text text={text} />}
      {isLoading && (
        <Spinner
          style={{
            width: "1.5rem",
            height: "1.5rem"
          }}
        />
      )}
    </Container>
  )
}

export default ButtonHandler
