import { createAsyncThunk } from "@reduxjs/toolkit"
import { getBlindMintCollection } from "services/collectionsRequest"

export const getCollectionBlindMint = createAsyncThunk(
  "collection/blindMint",
  async (slug: string) => {
    try {
      const {data} = await getBlindMintCollection(slug)
      return data
    } catch (error) {
      throw new Error(error.response.data)
    }
  }
)
