import AuthInputCaption from "@core/components/login/components/authInputCaption"
import LoginInputRoot from "@core/components/login/components/inputs/root"
import useFormContext from "utility/context/formContext/useFormContext"

export const cpfMask = (v: string) => {
  v = v?.replace(/\D/g, "")
  v = v?.replace(/(\d{3})(\d)/, "$1.$2")
  v = v?.replace(/(\d{3})(\d)/, "$1.$2")
  v = v?.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  return v
}

const KycCpfInput = () => {
  const {
    Container,
    Label,
    Input: { Container: InputContainer, Input, RightSideIcon }
  } = LoginInputRoot
  const { formData, errors, isSubmit, isFormLoading, handleInputChange } = useFormContext()

  console.log(errors)

  const value = formData?.identityDocument?.value
  const error = errors?.identityDocument
  const showError = isSubmit && error

  const handleInputChangeCpf = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cpfValue = cpfMask(e.target.value)
    if (cpfValue.length < 15) {
      handleInputChange({ target: { name: "identityDocument", value: { document: "cpf", value: cpfValue } } })
    }
  }

  return (
    <Container>
      <Label text="CPF" />
      <InputContainer>
        <Input
          id="kyc_cpf"
          name="cpf"
          type="text"
          placeholder="xxx.xxx.xxx-xx"
          value={cpfMask(value)}
          showError={showError}
          onChange={handleInputChangeCpf}
        />
        <RightSideIcon isLoading={isFormLoading} haveError={showError} size={"1.5rem"} />
      </InputContainer>
      <AuthInputCaption errorText={error} showError={showError} />
    </Container>
  )
}

export default KycCpfInput
