import { UserIdentityDocumentsEnum } from "interfaces/user"

export interface KycPersonTypeI {
  identityDocument: {
    document: UserIdentityDocumentsEnum
    value: string
  }
  email: string
}

export interface KycPersonalInfosI {
  fullName: string
  phone: string
  birthDate?: string
}

export interface KycFormData extends KycPersonTypeI, KycPersonalInfosI {}

const kycInitialValues = ({ userData, stage = "initial" }: { userData: any; stage?: "initial" | "reset" }) => {
  const { email } = userData || {}
  console.log(userData)
  const { fullName, phone, birthDate, identityDocument } = userData?.kyc || {}

  const personInitialValues: KycPersonTypeI = {
    identityDocument: {
      document: null,
      value: ""
    },
    email
  }

  if (stage === "initial" && identityDocument?.document) {
    personInitialValues["identityDocument"]["document"] = identityDocument?.document
    personInitialValues["identityDocument"]["value"] = identityDocument?.value
  }

  if (fullName) {
    personInitialValues["fullName"] = fullName
  }
  if (phone) {
    personInitialValues["phone"] = phone
  }
  if (birthDate) {
    personInitialValues["birthDate"] = birthDate
  }

  return personInitialValues
}

export default kycInitialValues
