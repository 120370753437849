import { UserKyc } from "interfaces/user"
import { axiosBase, getAxiosConfig } from "services/axiosBase"

interface AccessToken {
  accessToken: string
}

interface UserKycData {
  kyc: UserKyc
}

const patchChangeUserKyc = async ({ accessToken, kyc }: AccessToken & UserKycData) => {
  const config = getAxiosConfig(accessToken)
  const { data } = await axiosBase.patch(`users/change-kyc`, kyc, config)

  return data
}

export {
  patchChangeUserKyc
}
