import { IconType } from 'react-icons'

export interface IconProps {
  icon: IconType
  size?: number | string
  [key: string]: any
}

const Icon: React.FC<IconProps> = ({
  icon,
  size = 20,
  ...otherProps
}) => {
  const IconComponent = icon
  return <IconComponent size={size} {...otherProps}/>
}

export default Icon
