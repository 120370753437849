import useWagmi from "contexts/auth/hooks/useWagmi"
import { MetaMaskAuthStatusState, MetaMaskLoginCallbackFrom, MetaMaskStatusEnum } from "../metamaskAuthContext"
import handleMetaMaskError, { MetamaskErrors } from "./handleError"

interface SwitchMetaMaskNetworkProps
  extends Pick<MetaMaskAuthStatusState, "setMetaMaskAuthStatus">,
    Pick<ReturnType<typeof useWagmi>, "switchNetwork"> {
  proceedFrom: MetaMaskLoginCallbackFrom
  setShouldRestartAuthProcess?: boolean
}

const switchMetaMaskNetwork = async ({
  proceedFrom,
  setMetaMaskAuthStatus,
  switchNetwork: { switchNetworkAsync }
}: SwitchMetaMaskNetworkProps) => {
  const {
    SwitchNetwork_Pending: Pending,
    SwitchNetwork_Dismissed: Dismissed,
    SwitchNetwork_Failed: Failed,
    SwitchNetwork_Stack: Stack,
    SwitchNetwork_Success: Success
  } = MetaMaskStatusEnum

  const mustTrySwitchNetwork = (proceedFrom === "switch" && switchNetworkAsync) || (!proceedFrom && switchNetworkAsync)
  const response = {
    switchedNetworkData: null,
    stopProceed: false
  }

  if (mustTrySwitchNetwork) {
    setMetaMaskAuthStatus(Pending)

    try {
      const switchedNetworkData = await switchNetworkAsync()

      setMetaMaskAuthStatus(Success)

      response.switchedNetworkData = switchedNetworkData
      return response
    } catch (err) {
      console.log(err)
      console.log("Error", JSON.stringify(err))
      const mappedMetaMaskSwitchNetworkErrors = handleMetaMaskError(err?.name as MetamaskErrors)
      if (mappedMetaMaskSwitchNetworkErrors) {
        const { callback, status } = mappedMetaMaskSwitchNetworkErrors
        const authStatus = status === "dismiss" ? Dismissed : Stack

        callback(setMetaMaskAuthStatus, authStatus)
      } else {
        console.log("Error", JSON.stringify(err))
        setMetaMaskAuthStatus(Failed)
      }
      response.stopProceed = true
      return response
    }
  } else if (!switchNetworkAsync) {
    response.stopProceed = true
    return response
  }

  return null
}

export default switchMetaMaskNetwork
