import { CreatedCollection } from "interfaces/collection"
import { UserMembershipTierData } from "interfaces/shared/user-membership-tier-data.interface"
import { createContext, useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import { useLocation } from "react-router-dom"
import { useAppSelector, useWeispaceAppDispatch, useWeispaceAppSelector } from "redux/hooks"
import { fetchWhiteLabel } from "web-weispace/redux/whitelabelConfig"
import { getCommunityById, getUserMembershipTierByCommmunityId } from "web-weispace/services/axiosCommunityRequests"
import { fetchCollections } from "web-weispace/services/collections/collectionsActions"
import { fetchCommunities } from "web-weispace/services/communities/communitiesActions"
import { CreatedCommunity } from "web-weispace/services/communities/types"
import { setPageLoading } from "web-weispace/services/loading/loadingSlice"
import { fetchTokens } from "web-weispace/services/tokens/tokensActions"
import { useGlobalContext } from "web-weispace/utility/hooks/useContext"
import useLoadings from "web-weispace/utility/useLoadings"
import { benefitsPaths } from "web-weispace/views/benefits/router"
import { homePath } from "web-weispace/views/home/router"
import useFilterTokens from "web-weispace/views/my-nfts/utils/useFilterTokens"

export enum CommunityTypeEnum {
  loyalty = "loyalty",
  environmental = "environmental"
}

interface CommunitiesContextValue {
  ownedCommunities: CreatedCommunity[]
  currentCommunity: CreatedCommunity
  selectedCommunityId: string
  setSelectedCommunityId: (id: string) => void
  communityType: CommunityTypeEnum
  selectedCollectionId: string
  setSelectedCollectionId: (id: string) => void
  filteredCollections: CreatedCollection[]
  currentCollection: CreatedCollection
  selectedTokenType: string
  setSelectedTokenType: (type: string) => void
  filteredTokens: any[]
  isHomePath?: boolean
  userMembershipTierData: UserMembershipTierData
  isUserMembershipTierDataLoading: boolean
  resetAllData: () => void
}

const CommunitiesContext = createContext<CommunitiesContextValue | undefined>(undefined)

export const CommunitiesProvider = ({ children }) => {
  const dispatch = useWeispaceAppDispatch()
  const userFromGoogle = useAppSelector((state) => state.auth)
  const userFromMetamask = useAppSelector((state) => state.metamask)
  const JWT = userFromGoogle?.JWT ? userFromGoogle.JWT?.accessToken : userFromMetamask.JWT?.accessToken
  const userId = userFromGoogle?.JWT?.accessToken ? userFromGoogle?.JWT?.user?.id : userFromMetamask.JWT?.user?.id
  const location = useLocation()
  const { setShowTable } = useGlobalContext()
  const [isHomePath, setIsHomePath] = useState(location.pathname.includes(homePath.default))
  useEffect(() => {
    setIsHomePath(location.pathname.includes(homePath.default))
  }, [location.pathname])

  // Handle benefits render
  useEffect(() => {
    if (location.pathname.includes(benefitsPaths.home) || location.pathname.includes(homePath.default)) {
      setShowTable("table")
    } else {
      setShowTable("details")
    }
  }, [location.pathname])

  // Community related logic
  const cookiesCommunityId = localStorage.getItem("communityId")
  const { communities: reduxOwnedCommunities } = useWeispaceAppSelector((state) => state.communities)
  const [selectedCommunityId, setSelectedCommunityId] = useState<any>("")

  const { data: communityFromStorage, isLoading: isCommunityFromStorageLoading } = useQuery(
    ["communityFromStorage", cookiesCommunityId],
    async () => (await getCommunityById(JWT, cookiesCommunityId))?.data,
    {
      enabled: !!cookiesCommunityId,
      refetchOnWindowFocus: false
    }
  )

  const [ownedCommunities, setOwnedCommunities] = useState<CreatedCommunity[]>([])

  const populateOwnedCommunities = () => {
    if (reduxOwnedCommunities?.length > 0 || communityFromStorage) {
      if (!reduxOwnedCommunities?.find((community) => community?.id === communityFromStorage?.id)) {
        setOwnedCommunities([...reduxOwnedCommunities, communityFromStorage])
      } else {
        setOwnedCommunities(reduxOwnedCommunities)
      }
    }
  }

  useEffect(() => {
    populateOwnedCommunities()
  }, [reduxOwnedCommunities, communityFromStorage])

  const [currentCommunity, setCurrentCommunity] = useState<any>(
    ownedCommunities?.find((community) => community?.id === selectedCommunityId) ?? {}
  )
  const communityType: CommunityTypeEnum = CommunityTypeEnum[currentCommunity?.type] ?? CommunityTypeEnum.loyalty

  // Collection related logic
  const { collections } = useWeispaceAppSelector((state) => state.collections)
  const [filteredCollections, setFilteredCollections] = useState<CreatedCollection[]>(collections ?? [])
  const [selectedCollectionId, setSelectedCollectionId] = useState<string>("")
  const [currentCollection, setCurrentCollection] = useState<any>(
    collections?.find((collection) => collection?.id === selectedCollectionId) ?? {}
  )

  // Token related logic
  const { tokens } = useWeispaceAppSelector((state) => state.tokens)
  const [filteredTokens, setFilteredTokens] = useState<any[]>([])
  const [selectedTokenType, setSelectedTokenType] = useState<string>("")

  const firstRender = useRef(true)

  const { collectionsLoading, communitiesLoading, tokensLoading } = useLoadings()

  // Community change effect
  useEffect(() => {
    if (selectedCommunityId !== currentCommunity?.id) {
      setCurrentCommunity(ownedCommunities?.find((community) => community?.id === selectedCommunityId) ?? {})
      const filteredCollections =
        collections?.filter((collection) => collection?.communityId === selectedCommunityId) ?? []
      setFilteredCollections(filteredCollections)
      setSelectedCollectionId("")
      dispatch(fetchWhiteLabel({ communityId: selectedCommunityId }))
    }
  }, [selectedCommunityId])

  // Collection change effect
  useEffect(() => {
    if (selectedCollectionId !== currentCollection?.id) {
      setCurrentCollection(collections?.find((collection) => collection?.id === selectedCollectionId) ?? {})
    }
  }, [selectedCollectionId])

  // Loading effect
  useEffect(() => {
    if (!collectionsLoading && !communitiesLoading && !tokensLoading) {
      dispatch(setPageLoading(false))
    }
  }, [collectionsLoading, communitiesLoading, tokensLoading])

  // Filter collections effect
  useEffect(() => {
    if (collections?.length > 0) {
      const filteredCollections =
        collections?.filter((collection) => collection?.communityId === selectedCommunityId) ?? []
      setFilteredCollections(filteredCollections)
    }
  }, [collections])

  // Filter tokens effect
  useLayoutEffect(() => {
    const filteredTokens = useFilterTokens({
      tokens,
      selectedCollectionId,
      selectedCommunityId
    })
    setFilteredTokens(filteredTokens)
  }, [selectedCommunityId, selectedCollectionId, tokens])

  // Fetch initial data effect
  useLayoutEffect(() => {
    if (firstRender.current && JWT && userId) {
      dispatch(setPageLoading(true))
      dispatch(fetchTokens({ accessToken: JWT, userId }))
      firstRender.current = false
    }

    if (tokens?.length > 0) {
      dispatch(fetchCollections({ accessToken: JWT, tokens }))
      dispatch(fetchCommunities({ accessToken: JWT, tokens }))
    }
  }, [tokens, JWT, userId])

  useEffect(() => {
    if (!selectedCommunityId && ownedCommunities?.length > 0) {
      const communityId = cookiesCommunityId ?? ownedCommunities[0]?.id
      setSelectedCommunityId(communityId)
    }
  }, [ownedCommunities])

  const { data: userMembershipTierData, isFetching: isUserMembershipTierDataLoading } = useQuery(
    ["getUserMembershipTierByCommmunityId", selectedCommunityId, JWT],
    () =>
      getUserMembershipTierByCommmunityId(JWT, selectedCommunityId)
        .then((res) => res.data)
        .catch((err) => console.error(err)),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedCommunityId && !!JWT
    }
  )

  const resetAllData = () => {
    setSelectedCommunityId("")
    setSelectedCollectionId("")
    setSelectedTokenType("")
    dispatch(setPageLoading(true))
    setCurrentCommunity({})
    setOwnedCommunities([])
  }

  const contextValue: CommunitiesContextValue = {
    ownedCommunities,
    currentCommunity,
    selectedCommunityId,
    setSelectedCommunityId,
    communityType,
    filteredCollections,
    selectedCollectionId,
    setSelectedCollectionId,
    currentCollection,
    selectedTokenType,
    setSelectedTokenType,
    filteredTokens,
    isHomePath,
    userMembershipTierData,
    isUserMembershipTierDataLoading,
    resetAllData
  }

  return <CommunitiesContext.Provider value={contextValue}>{children}</CommunitiesContext.Provider>
}

export const useCommunitiesContext = (): CommunitiesContextValue => {
  const context = useContext(CommunitiesContext)
  if (!context) {
    throw new Error("useCommunitiesContext must be used within a CommunitiesProvider")
  }
  return context
}
