import {
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useSignMessage,
  chain as GoBlockChains,
  useSwitchNetwork
} from "wagmi"
import { MetaMaskConnector } from "wagmi/dist/connectors/metaMask"

const useWagmi = ({ expectedChain }) => {
  const { disconnect } = useDisconnect()
  const { connectAsync } = useConnect({
    connector: new MetaMaskConnector({ chains: [GoBlockChains.polygon, GoBlockChains.polygonMumbai] })
  })
  const { address, isConnected, connector } = useAccount()
  const { chain } = useNetwork()
  const { signMessageAsync, isError } = useSignMessage()
  const {
    switchNetworkAsync,
    error: switchError,
    isError: isSwitchError,
    status: switchStatus
  } = useSwitchNetwork({
    chainId: expectedChain
  })

  return {
    disconnect: {
      disconnect
    },
    connect: {
      connectAsync
    },
    account: {
      address,
      connector,
      isConnected
    },
    network: {
      chain
    },
    signMessage: {
      signMessageAsync,
      isError
    },
    switchNetwork: {
      switchNetworkAsync,
      switchError,
      isSwitchError,
      switchStatus
    }
  }
}

export default useWagmi
