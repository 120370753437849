import classNames from "classnames"
import { SwiperRootProps } from "."

interface StandardSwiperSlideProps extends SwiperRootProps {}

const StandardSwiperSlide = ({ children, className, ...rest }: StandardSwiperSlideProps) => {
  return (
    <div className={classNames("", className)} {...rest}
    >
      {children}
    </div>
  )
}

export default StandardSwiperSlide
