import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { cookies } from "../../utility/cookies"
import { getAnyTimeFromNow } from "utility/DateTimeUtils"
import { axiosBase } from "../axiosbase"

export const fetchJWTMetamask = createAsyncThunk(
  "metamask/JWT",
  async (body: {
    email?: string
    address: string
    network: string
    blockchain: string
    signature: string
    message: string
  }) => {
    try {
      const { data } = await axiosBase.post("/auth/metamask", body)
      return data
    } catch (error) {
      throw new Error(error.response.data)
    }
  }
)

export const fetchAutoLoginFromMetamask = createAsyncThunk("auth/jwt", async (config: object) => {
  try {
    const { data } = await axiosBase.post("/login/jwt", {}, config)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})
const session = () => {
  const res = cookies.get("userData")
  if (res?.type === "Metamask") {
    return res
  }
  return null
}

export const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    JWT: null || session(),
    JWTError: null,
    JWTLoading: false
  },
  reducers: {
    removeSessionMetamask: (state, action) => {
      state.JWTLoading = false
      state.JWT = null
      console.log("Removi, via metamask")
      cookies.remove("userData", { path: "/", domain: window.location.hostname })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJWTMetamask.pending, (state) => {
        state.JWTLoading = true
      })

      .addCase(fetchJWTMetamask.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        cookies.set(
          "userData",
          { ...payload, expires: new Date(getAnyTimeFromNow()), type: "Metamask" },
          { path: "/", domain: window.location.hostname }
        )
      })

      .addCase(fetchJWTMetamask.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchAutoLoginFromMetamask.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchAutoLoginFromMetamask.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        cookies.set(
          "userData",
          { ...payload, expires: new Date(getAnyTimeFromNow()), type: "auto-login" },
          { path: "/" }
        )
      })
      .addCase(fetchAutoLoginFromMetamask.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })
  }
})
export const { removeSessionMetamask } = authSlice.actions
export default authSlice.reducer
