import { isMobile } from "react-device-detect"

const Web3MobileIdleConnect = () => {
  if (isMobile) {
    return (
      <>
      </>
    )
  }

  return null
}

export default Web3MobileIdleConnect