import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import io from "socket.io-client"

import axios from "axios"
import { RootState } from "redux/store"
import QRCode from "../../../assets/images/QRCode.png"
import i18n from "configs/i18n"

const formatter = new Intl.NumberFormat(i18n.language === "en" ? "en-US" : "pt-BR", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const checkoutDiscount = {
  id: null,
  name: null,
  maxUsageLimit: null,
  discountPercentage: null,
  discountPriceInBrl: "",
  discountFinalPriceInBrl: "",
  hasAccess: false,
  userUsageCount: 0
}

export const fetchCheckout = createAsyncThunk<any, void, { state: RootState; rejectValue: any }>(
  "checkout/fetchCheckout",
  async (_: void, thunkAPI) => {
    try {
      const { bodyData, accessToken } = thunkAPI.getState().checkout.checkoutArguments
      const checkoutData = { ...bodyData }
      const { id } = thunkAPI.getState().checkout.checkoutDiscount
      if (id) checkoutData["selectedBenefits"] = [id]
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/store/checkout`, checkoutData, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      return data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response)
    }
  }
)

export const fetchBlindMintCheckout = createAsyncThunk<any, void, { state: RootState; rejectValue: any }>(
  "checkout/fetchBlindMintCheckout",
  async (_: void, thunkAPI) => {
    try {
      const { bodyData, accessToken } = thunkAPI.getState().checkout.checkoutArguments
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/store/checkout/blind-mint`, bodyData, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      return data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response)
    }
  }
)

export const fetchCheckoutTransactionRequestStatus = createAsyncThunk(
  "checkout/transactionRequestStatus",
  async ({ transactionId, accessToken }: any) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/transactions/${transactionId}/transactionRequest/status`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    return data.status
  }
)

export const preCheckout = createAsyncThunk<any, any, { state: RootState; rejectValue: any }>(
  "checkout/preCheckout",
  async ({ bodyData, accessToken, selectedDiscountsIds }: any, thunkAPI) => {
    try {
      const preCheckoutBodyData = { ...bodyData }
      if (preCheckoutBodyData.paymentMethod === "credit_card") preCheckoutBodyData.paymentMethod = "mercadopago"
      if (selectedDiscountsIds) preCheckoutBodyData.selectedBenefits = selectedDiscountsIds

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/store/preCheckout`, preCheckoutBodyData, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      return data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response)
    }
  }
)

export const fetchMyWayUserBenefits = createAsyncThunk<any, any, { state: RootState; rejectValue: any }>(
  "checkout/mywayUserBenefits",
  async ({ accessToken, companyId, benefitsIds, forceOpenModal = false }: any, thunkAPI) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/myway/${companyId}/check-user-benefits`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { reason: `Reddeming on token ${thunkAPI.getState().tokenBySlug?.tokenBySlug?.slug}`, benefitsIds },
      paramsSerializer: (params) => {
        let queryString = params.benefitsIds.map((str) => `benefitsIds[]=${encodeURIComponent(str)}`).join("&")
        queryString = params?.reason ? `${queryString}&reason=${encodeURIComponent(params.reason)}` : queryString
        return queryString
      }
    })

    const { isTokenGateActive: tokenIsTokenGateActive, isMyWayBenefitRequired: tokenIsMyWayBenefitRequired } =
      thunkAPI.getState().tokenBySlug.tokenBySlug || {}
    const { isTokenGateActive: collectionIsTokenGateActive, isMyWayBenefitRequired: collectionIsMyWayBenefitRequired } =
      thunkAPI.getState().token.collection || {}
    const isTokenGateActive = tokenIsTokenGateActive ?? (collectionIsTokenGateActive || false)
    const isMyWayBenefitRequired = tokenIsMyWayBenefitRequired ?? (collectionIsMyWayBenefitRequired || false)

    if (isTokenGateActive && isMyWayBenefitRequired && !data?.hasAccess && data?.accessConditions?.length > 0) {
      return {
        ...data,
        allow: false,
        forceOpenModal: !forceOpenModal
      }
    }

    return {
      ...data,
      allow: true,
      forceOpenModal: !forceOpenModal
    }
  }
)

export const fetchCheckoutDiscount = createAsyncThunk<any, any, { state: RootState; rejectValue: any }>(
  "checkout/discount",
  async ({ accessToken, companyId, benefitsIds }: any, thunkAPI) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/myway/${companyId}/check-user-benefits`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { reason: `Reddeming on token ${thunkAPI.getState().tokenBySlug?.tokenBySlug?.slug}`, benefitsIds },
        paramsSerializer: (params) => {
          let queryString = params.benefitsIds.map((str) => `benefitsIds[]=${encodeURIComponent(str)}`).join("&")
          queryString = params?.reason ? `${queryString}&reason=${encodeURIComponent(params.reason)}` : queryString
          return queryString
        }
      })

      return data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response)
    }
  }
)

export const watchCheckoutTransaction = (transactionId) => {
  const protocol = new URL(process.env.REACT_APP_API_URL).protocol
  const host = new URL(process.env.REACT_APP_API_URL).host
  const hostname = `${protocol}//${host}`
  const socket = io(hostname)

  socket.on("connect", () => {
    console.log("socket connected")
    console.log(`Monitorando Transaction: ${transactionId}`)
    socket.emit("lastStatusChangeFromTransaction", { transactionId })
  })

  socket.on("disconnect", () => {
    socket.removeAllListeners()
    console.log("socket disconnected")
  })

  socket.on("error", (error) => {
    socket.removeAllListeners()
    console.log("socket error", error)
  })
  return socket
}

export const checkoutSlice = createSlice({
  name: "checkoutSlice",
  initialState: {
    modalRescue: false,
    modalTokenGateDetails: false,
    modalSelectPayment: false,
    modalAddUserData: false,
    modalQRCodePayment: false,
    modalSelectProvider: false,
    checkoutBlindMint: {
      name: undefined,
      description: undefined,
      image: undefined,
      inventory: undefined,
      amount: undefined,
      type: "",
      banner: "",
      voucher: "",
      id: "",
      isGiveaway: undefined,
      collectionId: undefined,
      rescueLimit: undefined,
      tokenId: undefined
    },
    checkoutArguments: {
      bodyData: {
        paymentMethod: "",
        paymentProvider: "",
        paymentCurrency: "brl",
        amount: "1",
        offerId: ""
      },
      accessToken: ""
    },

    checkoutQrCode: {
      qrcode: "",
      qrcodeText: "",
      externalUrl: "",
      to: "",
      value: "",
      finalPrice: "",
      data: "",
      network: "",
      valueInWei: ""
    },
    checkoutQrCodeLoading: true,
    checkoutQrCodeErrorMessage: "",

    checkoutToken: {
      id: "",
      offerId: "",
      sale: {
        price: "",
        currency: "",
        decimals: ""
      },
      carbon: {
        price: "",
        currency: "",
        decimals: "",
        compensationRateInKgPerUnit: ""
      },
      seller: {
        id: "",
        name: ""
      },
      offerType: "",
      priceInBrl: "",
      taxesInBrl: "",
      tokenId: "",
      companyId: "",
      communityId: "",
      collectionId: "",
      transactionType: "",
      owners: [],
      inventory: 0,
      amount: 0,
      collection: {},
      voucherCode: "",
      name: "",
      hideSupply: false,
      displayMediaUrl: "",
      supply: ""
    },

    checkoutDiscounts: {
      hasAccess: false,
      hasDiscountAccess: false,
      requirements: [],
      discountPercentage: 0,
      discountPriceInBrl: "",
      discountFinalPriceInBrl: "",
      selectedDiscount: [],
      availableDiscounts: []
    },

    checkoutDiscount,
    checkoutDiscountIsLoading: false,
    checkoutDiscountError: null,

    checkoutResponse: {
      paymentObject: {
        qrcode: "",
        qrcodeText: "",
        externalUrl: "",
        to: "",
        value: "",
        data: "",
        network: "",
        valueInWei: "",
        free: false
      },
      transactionId: "",
      status: ""
    },
    checkoutResponseLoading: true,
    checkoutResponseErrorMessage: undefined,

    preCheckoutResponse: {
      priceInBrl: "",
      totalFeesInBrl: "",
      totalPriceWithFeesInBrl: ""
    },
    preCheckoutResponseLoading: true,
    preCheckoutResponseErrorMessage: undefined,

    checkoutTransactionRequestStatus: "",
    checkoutTransactionRequestStatusLoading: false,
    checkoutTransactionRequestStatusErrorMessage: undefined,
    checkoutPaymentStatus: "",

    shouldOpenSelectPaymentModal: false,
    shouldDoPost: false,
    isButtonLoading: false,
    isPolling: false,

    checkoutTimer: 300,
    checkoutPaymentMethod: "",
    checkoutPaymentCurrency: "",
    checkoutMyWayUserBenefitsLoading: false,
    checkoutMyWayUserBenefitsErrorMessage: undefined,

    isCheckoutDiscountsModalOpen: false,

    isTransferPaymentModalOpen: false,
    transferModalStep: ""
  },
  reducers: {
    setShowModalSelectProvider: (state, { payload }) => {
      state.modalSelectProvider = payload
    },
    setShowTransferPaymentModal: (state, { payload }) => {
      state.isTransferPaymentModalOpen = payload
    },
    setTransferModalStep: (state, { payload }) => {
      state.transferModalStep = payload
    },
    clearTokenGateRequirements(state) {
      state.checkoutDiscounts.requirements = []
    },
    clearDiscounts: (state) => {
      state.checkoutDiscounts.hasAccess = false
      state.checkoutDiscounts.discountPercentage = 0
      state.checkoutDiscounts.discountPriceInBrl = ""
      state.checkoutDiscounts.discountFinalPriceInBrl = ""
      state.checkoutDiscounts.requirements = []
      state.checkoutDiscounts.selectedDiscount = []
      state.checkoutDiscounts.availableDiscounts = []
      state.checkoutMyWayUserBenefitsLoading = false
    },
    setPaymentProvider: (state, { payload }) => {
      state.checkoutArguments.bodyData.paymentProvider = payload
    },
    setPaymentMethod: (state, { payload }) => {
      state.checkoutPaymentMethod = payload
    },
    setPaymentCurrency: (state, { payload }) => {
      state.checkoutPaymentCurrency = payload
    },
    setCheckoutTimer: (state, { payload }) => {
      state.checkoutTimer = payload
    },
    setCheckoutResponseErrorMessage(state, { payload }) {
      state.checkoutResponseErrorMessage = payload
    },
    setIsPolling: (state, { payload }) => {
      state.isPolling = payload
    },
    setShouldDoPost: (state, { payload }) => {
      state.shouldDoPost = payload
    },
    setIsButtonLoading: (state, { payload }) => {
      state.isButtonLoading = payload
    },
    setShouldOpenSelectPaymentModal: (state, { payload }) => {
      state.shouldOpenSelectPaymentModal = payload
    },
    setCheckoutModal: (state, { payload }) => {
      state.modalRescue = payload
    },
    setTokenGateDetailsModal: (state, { payload }) => {
      state.modalTokenGateDetails = payload
    },
    setShowModalSelectPayment: (state, { payload }) => {
      state.modalSelectPayment = payload
      state.checkoutQrCodeLoading = true
      state.checkoutQrCode = {
        qrcode: "",
        qrcodeText: "",
        externalUrl: "",
        finalPrice: "",
        to: "",
        value: "",
        data: "",
        network: "",
        valueInWei: ""
      }
    },
    setShowModalAddUserData: (state, { payload }) => {
      state.modalAddUserData = payload
      state.checkoutQrCodeLoading = true
    },
    setShowModalQRCodePayment: (state, { payload }) => {
      state.modalQRCodePayment = payload
      if (payload) {
        state.isButtonLoading = true
      } else {
        state.checkoutQrCodeErrorMessage = null
      }
    },
    clearCheckoutQrCode: (state) => {
      state.checkoutQrCode = {
        qrcode: "",
        qrcodeText: "",
        externalUrl: "",
        to: "",
        value: "",
        finalPrice: "",
        data: "",
        network: "",
        valueInWei: ""
      }
    },
    clearPreCheckoutResponse: (state) => {
      state.preCheckoutResponse = {
        priceInBrl: "",
        totalFeesInBrl: "",
        totalPriceWithFeesInBrl: ""
      }
    },
    clearCheckoutResponse: (state) => {
      state.checkoutResponse = {
        paymentObject: {
          qrcode: "",
          qrcodeText: "",
          externalUrl: "",
          to: "",
          value: "",
          data: "",
          network: "",
          valueInWei: "",
          free: false
        },
        transactionId: "",
        status: ""
      }
    },
    clearCheckoutToken: (state) => {
      state.checkoutToken = {
        id: "",
        offerId: "",
        sale: {
          price: "",
          currency: "",
          decimals: ""
        },
        carbon: {
          price: "",
          currency: "",
          decimals: "",
          compensationRateInKgPerUnit: ""
        },
        seller: {
          id: "",
          name: ""
        },
        offerType: "",
        priceInBrl: "",
        taxesInBrl: "",
        tokenId: "",
        companyId: "",
        communityId: "",
        collectionId: "",
        transactionType: "",
        owners: [],
        inventory: 0,
        amount: 0,
        collection: {},
        voucherCode: "",
        name: "",
        hideSupply: false,
        displayMediaUrl: "",
        supply: ""
      }
    },
    clearBodyData: (state) => {
      state.checkoutArguments.bodyData = {
        paymentMethod: "",
        paymentProvider: "",
        paymentCurrency: "",
        amount: "1",
        offerId: ""
      }
    },
    clearCheckoutBlindMint: (state) => {
      state.checkoutBlindMint = {
        ...state.checkoutBlindMint,
        voucher: "",
        id: ""
      }
    },
    setCheckoutQrCode: (state, { payload }) => {
      state.checkoutQrCode = payload
    },
    setCheckoutToken: (state, { payload }) => {
      state.checkoutToken = payload
    },
    setCheckoutQrCodeErrorMessage: (state, { payload }) => {
      state.checkoutQrCodeErrorMessage = payload
    },
    setCheckoutQrCodeLoading: (state, { payload }) => {
      state.checkoutQrCodeLoading = payload
    },
    setCheckoutArguments: (state, { payload }) => {
      state.checkoutArguments = payload
    },
    setCheckoutTransactionRequestStatus: (state, { payload }) => {
      state.checkoutTransactionRequestStatus = payload
    },
    setCheckoutPaymentStatus: (state, { payload }) => {
      state.checkoutPaymentStatus = payload
    },
    setCheckoutBlindMint: (state, { payload }) => {
      state.checkoutBlindMint = payload
    },
    setIsCheckoutDiscountsModalOpen: (state, { payload }) => {
      state.isCheckoutDiscountsModalOpen = payload
    },
    clearCheckoutDiscount: (state) => {
      state.checkoutDiscount = checkoutDiscount
    },
    setCheckoutDiscount: (state, { payload }) => {
      state.checkoutDiscount = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckout.pending, (state) => {
        state.checkoutResponseLoading = true
        state.shouldDoPost = false
      })
      .addCase(fetchCheckout.fulfilled, (state, { payload }) => {
        state.transferModalStep = "transferInfos"
        state.checkoutResponseLoading = false
        state.shouldDoPost = false
        state.checkoutResponse = payload
        if (payload?.paymentObject) {
          state.isPolling = true
          state.checkoutQrCode = payload?.paymentObject
          if (payload?.paymentObject?.totalPriceWithFeesInBrl) {
            state.checkoutQrCode.finalPrice = `R$ ${formatter.format(
              Number(payload?.paymentObject?.totalPriceWithFeesInBrl)
            )}`
          }
          if (payload?.paymentObject?.free) {
            state.modalQRCodePayment = false
          }
          state.checkoutQrCodeLoading = false
        }
      })
      .addCase(preCheckout.pending, (state) => {
        state.preCheckoutResponseLoading = true
      })
      .addCase(preCheckout.fulfilled, (state, { payload }) => {
        state.preCheckoutResponse = payload
        if (payload.totalFeesInBrl) {
          state.preCheckoutResponse.totalFeesInBrl = `R$ ${formatter.format(Number(payload.totalFeesInBrl / 100))}`
        }
        state.preCheckoutResponse.totalPriceWithFeesInBrl = payload.totalPriceWithFeesInBrl
          ? `R$ ${formatter.format(Number(payload.totalPriceWithFeesInBrl / 100))}`
          : "R$ 0,00"

        state.preCheckoutResponseLoading = false
      })
      .addCase(preCheckout.rejected, (state, { payload }) => {
        state.preCheckoutResponseLoading = false
        state.preCheckoutResponseErrorMessage = payload
      })
      .addCase(fetchCheckout.rejected, (state, { payload }) => {
        state.checkoutResponseErrorMessage = payload?.data?.message
        state.isButtonLoading = false
        state.isPolling = false
        state.shouldDoPost = false
        state.modalSelectPayment = false
        state.modalQRCodePayment = false
        state.checkoutResponseLoading = false
        state.transferModalStep = "errorCheckout"
      })
      .addCase(fetchBlindMintCheckout.pending, (state) => {
        state.checkoutResponseLoading = true
      })
      .addCase(fetchBlindMintCheckout.fulfilled, (state, { payload }) => {
        state.checkoutResponseLoading = false
        state.shouldDoPost = false
        state.checkoutResponse = payload
        if (payload?.paymentObject) {
          state.isPolling = true
          state.checkoutQrCode = payload?.paymentObject
          if (payload?.paymentObject?.totalPriceWithFeesInBrl) {
            state.checkoutQrCode.finalPrice = `R$ ${formatter.format(
              Number(payload?.paymentObject?.totalPriceWithFeesInBrl)
            )}`
          }
          if (payload?.paymentObject?.free) {
            state.modalQRCodePayment = false
          }
          state.checkoutQrCodeLoading = false
        }
      })
      .addCase(fetchBlindMintCheckout.rejected, (state, { payload }) => {
        state.checkoutResponseLoading = false
        state.checkoutResponseErrorMessage = payload?.data?.message
        state.isButtonLoading = false
        state.isPolling = false
        state.shouldDoPost = false
      })
      .addCase(fetchCheckoutTransactionRequestStatus.pending, (state) => {
        state.checkoutTransactionRequestStatusLoading = true
      })
      .addCase(fetchCheckoutTransactionRequestStatus.fulfilled, (state, { payload }: any) => {
        state.checkoutTransactionRequestStatusLoading = false
        state.checkoutTransactionRequestStatus = payload
      })
      .addCase(fetchCheckoutTransactionRequestStatus.rejected, (state, { payload }) => {
        state.checkoutTransactionRequestStatusLoading = false
        state.checkoutTransactionRequestStatusErrorMessage = payload
      })

      .addCase(fetchMyWayUserBenefits.pending, (state) => {
        state.checkoutMyWayUserBenefitsLoading = true
        state.isButtonLoading = true
      })
      .addCase(fetchMyWayUserBenefits.fulfilled, (state, { payload }) => {
        state.isButtonLoading = false
        state.checkoutMyWayUserBenefitsLoading = false
        state.checkoutDiscounts.hasAccess = payload.hasAccess || false
        state.checkoutDiscounts.hasDiscountAccess = payload.hasDiscountAccess || false
        state.checkoutDiscounts.discountPercentage = payload.discountPercentage || null

        if (!payload?.hasAccess) {
          const requirements = []
          payload?.accessConditions?.forEach((condition) => {
            const conditionByCommunity = condition.requirements?.[0]?.checkByCommunity
            conditionByCommunity?.forEach((communityCondition) => {
              requirements.push(communityCondition)
            })
          })
          state.checkoutDiscounts.requirements = requirements
        }

        const discountPercentageNumber = Number(state.checkoutDiscounts.discountPercentage) / 10000

        if (state.checkoutToken.transactionType === "sale" && state.checkoutToken?.priceInBrl) {
          const priceInBrlNumber = Number(
            state.checkoutToken?.priceInBrl?.replace("R$", "")?.replace(".", "")?.replace(",", ".")
          )
          const discountPriceInBrlNumber = priceInBrlNumber * discountPercentageNumber
          const discountFinalPriceInBrlNumber = priceInBrlNumber - discountPriceInBrlNumber

          state.checkoutDiscounts.discountPriceInBrl = discountPriceInBrlNumber
            ? `R$ ${formatter.format(discountPriceInBrlNumber)}`
            : "R$ 0,00"
          state.checkoutDiscounts.discountFinalPriceInBrl =
            discountFinalPriceInBrlNumber >= 0
              ? `R$ ${formatter.format(discountFinalPriceInBrlNumber)}`
              : state.preCheckoutResponse.totalPriceWithFeesInBrl

          if (payload.allow) {
            state.modalSelectProvider = false
            state.modalSelectPayment = true
          } else {
            state.modalSelectProvider = false
            state.modalRescue = true
          }
        } else if (
          state.checkoutToken.transactionType === "airdrop" ||
          state.checkoutToken.transactionType === "poap" ||
          state.checkoutToken.transactionType === "poap_zk" ||
          state.checkoutToken.transactionType === "membership"
        ) {
          state.modalRescue = payload.forceOpenModal
        }
      })
      .addCase(fetchMyWayUserBenefits.rejected, (state, { payload }) => {
        state.checkoutMyWayUserBenefitsLoading = false
        state.checkoutMyWayUserBenefitsErrorMessage = payload
        state.isButtonLoading = false
      })

      .addCase(fetchCheckoutDiscount.pending, (state) => {
        state.checkoutDiscountIsLoading = true
      })
      .addCase(fetchCheckoutDiscount.fulfilled, (state, { payload }: any) => {
        const discountPercentageNumber = Number(payload.discountPercentage) / 10000

        const priceInBrlNumber = Number(
          state.checkoutToken?.priceInBrl?.replace("R$", "")?.replace(".", "")?.replace(",", ".")
        )

        const discountPriceInBrlNumber = priceInBrlNumber * discountPercentageNumber
        const discountFinalPriceInBrlNumber = priceInBrlNumber - discountPriceInBrlNumber

        state.checkoutDiscount.id = payload.id
        state.checkoutDiscount.maxUsageLimit = payload.maxUsageLimit
        state.checkoutDiscount.name = payload.name
        state.checkoutDiscount.hasAccess = payload.hasDiscountAccess || false
        state.checkoutDiscount.userUsageCount = payload.userUsageCount || 0
        state.checkoutDiscount.discountPercentage = payload.discountPercentage || null
        state.checkoutDiscount.discountPriceInBrl = discountPriceInBrlNumber
          ? `R$ ${formatter.format(discountPriceInBrlNumber)}`
          : "R$ 0,00"
        state.checkoutDiscount.discountFinalPriceInBrl =
          discountFinalPriceInBrlNumber >= 0
            ? `R$ ${formatter.format(discountFinalPriceInBrlNumber)}`
            : state.preCheckoutResponse.totalPriceWithFeesInBrl

        state.checkoutDiscountIsLoading = false
      })
      .addCase(fetchCheckoutDiscount.rejected, (state, { payload }) => {
        state.checkoutDiscountIsLoading = false
        state.checkoutDiscountError = payload
      })
  }
})

export const {
  clearDiscounts,
  setPaymentProvider,
  setCheckoutTimer,
  setPaymentMethod,
  setPaymentCurrency,
  clearBodyData,
  setShowModalSelectPayment,
  setShowModalAddUserData,
  setShouldDoPost,
  setIsButtonLoading,
  setIsPolling,
  setCheckoutResponseErrorMessage,
  setShowModalQRCodePayment,
  setCheckoutModal,
  setTokenGateDetailsModal,
  clearTokenGateRequirements,
  clearCheckoutQrCode,
  setCheckoutToken,
  setCheckoutQrCodeErrorMessage,
  setCheckoutQrCode,
  setCheckoutArguments,
  setCheckoutQrCodeLoading,
  setCheckoutTransactionRequestStatus,
  setCheckoutPaymentStatus,
  clearCheckoutResponse,
  clearPreCheckoutResponse,
  setShouldOpenSelectPaymentModal,
  setShowModalSelectProvider,
  clearCheckoutToken,
  setCheckoutBlindMint,
  clearCheckoutBlindMint,
  setIsCheckoutDiscountsModalOpen,
  clearCheckoutDiscount,
  setShowTransferPaymentModal,
  setTransferModalStep
} = checkoutSlice.actions

export default checkoutSlice.reducer
