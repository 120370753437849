import useEmailAuth from "contexts/auth/hooks/useEmailAuth"
import EmailAuthContext, { EmailAuthProviderValues } from "./emailAuthContext"
import useAuthContext from "contexts/auth/AuthInContext"
import { useEffect } from "react"
import { AuthModalsEnum } from "contexts/auth/AuthContext"
import { useAppSelector } from "redux/hooks"
import useAccessGuardContext from "web-marketplace/contexts/accessGuard/useAccessGuardContext"

interface EmailAuthProviderProps {
  children: React.ReactNode
}

const EmailAuthProvider = ({ children }: EmailAuthProviderProps) => {
  const { Provider } = EmailAuthContext
  const { networkMap, setCurrentAuthStageShowing, setIsAuthModalOpen, setIsUserLoggedIn, currentAuthStageShowing } =
    useAuthContext()

  const { isAccessGuardShieldEnabled } = useAccessGuardContext() || {}
  const { authRequiresKyc } = useAppSelector((state) => state.auth)

  const { emailForm, ...emailCallbacks } = useEmailAuth({
    networkMap,
    setCurrentAuthStageShowing,
    setIsAuthModalOpen,
    setIsUserLoggedIn,
    currentAuthStageShowing
  })

  const providerValues: EmailAuthProviderValues = {
    ...emailForm,
    ...emailCallbacks
  }

  useEffect(() => {
    if (currentAuthStageShowing === AuthModalsEnum.OTP_VERIFICATION && emailForm?.formData?.email === "") {
      setCurrentAuthStageShowing(AuthModalsEnum.LOGIN)
    }
  }, [currentAuthStageShowing, emailForm?.formData?.email])

  useEffect(() => {
    //TODO E se o usuário estiver logado.
    const isUserLoggedIn = false
    const userHavePermission = false
    if (authRequiresKyc && isUserLoggedIn && userHavePermission) {
      setCurrentAuthStageShowing(AuthModalsEnum.KYC)
    }
  }, [authRequiresKyc])

  return <Provider value={providerValues}>{children}</Provider>
}

export default EmailAuthProvider
