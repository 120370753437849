import MarketPlaceAuthProvider from "./auth/marketplaceAuthProvider"
import MarketPlaceGuardProvider from "./guard"

const MarketPlaceProviders = ({ children }) => {
  return (
    <>
      <MarketPlaceAuthProvider>
        <MarketPlaceGuardProvider>{children}</MarketPlaceGuardProvider>
      </MarketPlaceAuthProvider>
    </>
  )
}

export default MarketPlaceProviders
