import { Dispatch, SetStateAction, createContext } from "react"
import useAuthGuardContext from "./useAuthGuardContext"

export interface AuthGuardShieldStateI {
  isAuthGuardShieldEnabled: boolean
  setIsAuthGuardShieldEnabled: Dispatch<SetStateAction<boolean>>
}

export interface AuthGuardContextI extends AuthGuardShieldStateI {}

const AuthGuardContext = createContext({} as AuthGuardContextI)

export default AuthGuardContext
export { useAuthGuardContext }
