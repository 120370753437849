import useAuthContext from "contexts/auth/AuthInContext"
import { useEffect, useState } from "react"
import MetaMaskAuthContext, {
  HandleMetaMaskLogin,
  MetaMaskAuthProviderValues,
  MetaMaskStatusEnum
} from "./metamaskAuthContext"

import { AuthModalsEnum } from "contexts/auth/AuthContext"
import { isMobile } from "react-device-detect"
import handleMetaMaskMobileLogin from "./hooks/handleMobileLogin"
import connectMetaMask from "./hooks/connect"

import switchMetaMaskNetwork from "./hooks/switchNetwork"
import validateUserNetwork from "./hooks/validateNetwork"
import metaMaskSignInMessage from "./hooks/signInMessage"
import { useAppDispatch } from "redux/hooks"
import handleFetchMetaMaskJwt from "./hooks/fetchJwt"
import {
  Web3AuthLoadingState,
  Web3AuthProvidersEnum,
  Web3AuthStatusState,
  Web3SelectedAuthProviderState
} from "../../web3AuthContext"
import detectWeb3Provider from "../../hooks/detect"
import walletsConnected from "./hooks/walletsConnected"

interface MetaMaskAuthProviderProps
  extends Pick<Web3SelectedAuthProviderState, "selectedWeb3AuthProvider">,
    Pick<Web3AuthLoadingState, "isWeb3AuthLoading">,
    Pick<Web3AuthStatusState, "setWeb3AuthStatus"> {
  children: React.ReactNode
}

const MetamaskAuthProvider = ({
  children,
  selectedWeb3AuthProvider,
  setWeb3AuthStatus,
  isWeb3AuthLoading
}: MetaMaskAuthProviderProps) => {
  const dispatch = useAppDispatch()
  const { Provider } = MetaMaskAuthContext
  const {
    setCurrentAuthStageShowing,
    wagmiConfigs,
    expectedChain,
    networkMap,
    setIsUserLoggedIn,
    currentAuthStageShowing
  } = useAuthContext()
  const {
    connect,
    network,
    switchNetwork,
    account,
    disconnect: { disconnect },
    signMessage
  } = wagmiConfigs

  const { chain } = network
  const { address } = account

  const [isMetaMaskAuthLoading, setIsMetaMaskAuthLoading] = useState<boolean>(false)
  const [metaMaskAuthStatus, setMetaMaskAuthStatus] = useState<MetaMaskStatusEnum>(null)
  const [shouldRestartAuthProcess, setShouldRestartAuthProcess] = useState<boolean>(false)

  const stopMetaMaskAuthRequest = () => {
    setIsMetaMaskAuthLoading(false)
  }

  const handleMetaMaskLogin: HandleMetaMaskLogin = async (proceedFrom) => {
    setIsMetaMaskAuthLoading(true)
    setShouldRestartAuthProcess(false)

    const { stopProceed: stopInDetect } = detectWeb3Provider({
      web3Provider: Web3AuthProvidersEnum.METAMASK,
      setWeb3AuthStatus
    })

    if (stopInDetect) {
      setCurrentAuthStageShowing(AuthModalsEnum.WEB3_UNAVAILABLE)
      return stopMetaMaskAuthRequest()
    } else {
      if (isMobile) return handleMetaMaskMobileLogin()
      setCurrentAuthStageShowing(AuthModalsEnum.WEB3_LOGIN)
    }

    const { stopProceed: stopInLogin, userData } =
      (await connectMetaMask({
        proceedFrom,
        connect,
        network,
        setMetaMaskAuthStatus,
        account
      })) || {}
    if (stopInLogin) return stopMetaMaskAuthRequest()

    const { stopProceed: stopInSwitchNetwork, switchedNetworkData } =
      (await switchMetaMaskNetwork({ proceedFrom, setMetaMaskAuthStatus, switchNetwork })) || {}
    if (stopInSwitchNetwork) {
      if (!switchNetwork?.switchNetworkAsync) {
        setShouldRestartAuthProcess(true)
        return
      } else {
        return stopMetaMaskAuthRequest()
      }
    }

    const {
      stopProceed: stopInValidateNetwork,
      userAddress,
      userChainId
    } = validateUserNetwork({
      expectedChain,
      userAddress: userData?.address || address,
      userChainId: proceedFrom ? switchedNetworkData?.id : userData?.chain?.id || network?.chain?.id
    }) || {}
    if (stopInValidateNetwork) {
      return handleMetaMaskLogin("switch")
    }

    // //TODO Verificar se continua na mesma chain

    const signedMessageByGoTokens = "Assine essa mensagem para garantirmos que você é o dono da carteira"

    const { stopProceed: stopInMetaMaskSignInMessage, signedMessageData } =
      (await metaMaskSignInMessage({
        userAddress,
        userChainId,
        message: signedMessageByGoTokens,
        setMetaMaskAuthStatus,
        signMessage
      })) || {}
    if (stopInMetaMaskSignInMessage) return stopMetaMaskAuthRequest()

    handleFetchMetaMaskJwt({
      setIsUserLoggedIn,
      setMetaMaskAuthStatus,
      dispatch,
      address: userAddress || address,
      message: signedMessageByGoTokens,
      network: networkMap[userChainId || network?.chain?.id],
      signature: signedMessageData
    })

    setIsMetaMaskAuthLoading(false)
  }

  const resetMetaMaskAuth = () => {
    setMetaMaskAuthStatus(null)
    setIsMetaMaskAuthLoading(false)
    disconnect()
  }

  useEffect(() => {
    if (selectedWeb3AuthProvider === Web3AuthProvidersEnum.METAMASK && isWeb3AuthLoading) {
      handleMetaMaskLogin()
    }
  }, [selectedWeb3AuthProvider, isWeb3AuthLoading])

  useEffect(() => {
    if (chain && expectedChain && shouldRestartAuthProcess) {
      handleMetaMaskLogin("switch")
    }
  }, [chain, expectedChain, shouldRestartAuthProcess])

  useEffect(() => {
    if (
      (currentAuthStageShowing === AuthModalsEnum.WEB3_LOGIN ||
        currentAuthStageShowing === AuthModalsEnum.WEB3_UNAVAILABLE) &&
      !selectedWeb3AuthProvider
    ) {
      stopMetaMaskAuthRequest()
      setCurrentAuthStageShowing(AuthModalsEnum.LOGIN)
    }
  }, [currentAuthStageShowing])

  const providerValues: MetaMaskAuthProviderValues = {
    resetMetaMaskAuth,
    handleMetaMaskLogin,
    isMetaMaskAuthLoading,
    setIsMetaMaskAuthLoading,
    metaMaskAuthStatus,
    setMetaMaskAuthStatus
  }

  return <Provider value={providerValues}>{children}</Provider>
}

export default MetamaskAuthProvider
