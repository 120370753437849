import MetamaskStatus from "@core/components/login-modal/loading/metamask/status"
import useGetWeb3ProvidersData from "@core/components/login/utils/hooks/useGetWeb3ProvidersData"

import DisplaySource from "components/displaySource"
import useMetaMaskAuthContext from "contexts/auth/methods/web3/providers/metamask/useMetamaskAuthContext"
import { Web3AuthProvidersEnum } from "contexts/auth/methods/web3/web3AuthContext"

const Web3MetaMaskLoginBody = () => {
  const { metaMaskAuthStatus } = useMetaMaskAuthContext()

  const { logo } = useGetWeb3ProvidersData({ provider: Web3AuthProvidersEnum.METAMASK }) || {}

  return (
    <div className="d-flex flex-column h-100 justify-content-center gap-2">
      <div className="d-flex flex-column gap-1 ">
        <DisplaySource src={logo} centralized contained size={{ maxWidth: "4rem" }} />
        <div className="w-100 rounded-circle float-end" style={{ border: "1px solid #f68615" }}></div>
      </div>
      <div style={{ minHeight: "200px" }} className="d-flex flex-column gap-2 w-100">
        {metaMaskAuthStatus?.startsWith("1") && (
          <MetamaskStatus
            dismissText="Metamask_Connection_Dismiss"
            pendingText="Metamask_Connection_Pending"
            alreadyPendingText="Metamask_Connection_Already_Pending"
            retryIn={"connect"}
          />
        )}
        {metaMaskAuthStatus?.startsWith("2") && (
          <MetamaskStatus
            dismissText="Metamask_SwitchNetwork_Dismiss"
            pendingText="Metamask_SwitchNetwork_Pending"
            alreadyPendingText="Metamask_SwitchNetwork_Already_Pending"
            retryIn={"switch"}
          />
        )}
        {metaMaskAuthStatus?.startsWith("3") && (
          <MetamaskStatus
            dismissText="Metamask_Sign_Dismiss"
            pendingText="Metamask_Sign_Pending"
            alreadyPendingText="Metamask_Sign_Already_Pending"
            retryIn={"signMessage"}
          />
        )}
      </div>
    </div>
  )
}

export default Web3MetaMaskLoginBody
