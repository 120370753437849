import { useEffect, useState } from "react"
import UnavailableContentContext, {
  UnavailableContentContextI,
  UnavailableContentEnum
} from "./unavailableContentGuardContext"
import useAccessGuardContext from "../accessGuard/useAccessGuardContext"
import { useLocation } from "react-router-dom"
import { useAppSelector } from "redux/hooks"

type UnavailableParamsContentMap = {
  [key in UnavailableContentEnum]: Function
}

interface UnavailableContentProviderProps {
  children: React.ReactNode
}

/**
 * Controla o acesso a conteúdos indisponíveis
 * @requires AccessGuardProvider - Deve ser "Pai" desse provider
 */
const UnavailableContentGuardProvider = ({ children }: UnavailableContentProviderProps) => {
  const { Provider } = UnavailableContentContext
  const { isAccessGuardShieldEnabled } = useAccessGuardContext()
  const { whiteLabelGuard } = useAppSelector((state) => state.whitelabelConfig)
  const accessGuardAlwaysOn = whiteLabelGuard?.private?.enabled
  const [isCheckAccessGuardShieldEnabled, setIsCheckAccessGuardShieldEnabled] = useState<boolean>(accessGuardAlwaysOn)
  const [isUnavailableContentShieldEnabled, setIsUnavailableContentShieldEnabled] = useState<boolean>(false)

  const location = useLocation()
  const params = location?.pathname.split("/")

  useEffect(() => {
    const handleHideUnavailableContent = () => {
      const unavailableParamsContentMap: UnavailableParamsContentMap = {
        marketplace: () => {
          setIsUnavailableContentShieldEnabled(true)
        },
        "marketplace-company": () => {
          setIsUnavailableContentShieldEnabled(true)
        }
      }

      const unavailableContentCallback = unavailableParamsContentMap?.[params[1]]
      if (unavailableContentCallback) {
        console.log("UnavailableContentGuardProvider => Hiding unavailable content")
        unavailableContentCallback()
      } else {
        console.log("UnavailableContentGuardProvider => No unavailable content to hide")
        setIsUnavailableContentShieldEnabled(false)
      }
    }

    if (isCheckAccessGuardShieldEnabled || isAccessGuardShieldEnabled) {
      console.log("UnavailableContentGuardProvider => CheckAccessGuardShield is enabled")
      handleHideUnavailableContent()
    }
  }, [isCheckAccessGuardShieldEnabled, isAccessGuardShieldEnabled, params?.[1]])

  useEffect(() => {
    if (accessGuardAlwaysOn) {
      console.log("UnavailableContentGuardProvider => AccessGuard is permanently on")
      setIsCheckAccessGuardShieldEnabled(accessGuardAlwaysOn)
    }
  }, [accessGuardAlwaysOn])

  const providerValues: UnavailableContentContextI = {
    isUnavailableContentShieldEnabled,
    setIsUnavailableContentShieldEnabled
  }

  return <Provider value={providerValues}>{children}</Provider>
}

export default UnavailableContentGuardProvider
