import { AuthLoginStages, AuthModalsEnum, useAuthContext } from "contexts/auth/AuthContext"
import LoginStageHeader from "./stages/login/header"
import LoginStageBody from "./stages/login/body"
import LoginContentProvider from "./context/loginContentProvider"
import LoginOtpStageBody from "./stages/otp/body"
import LoginOtpStageHeader from "./stages/otp/header"
import classNames from "classnames"
import LoginWeb3StageHeader from "./stages/web3/login/header"
import LoginWeb3StageBody from "./stages/web3/login/body"
import Web3IdleLoginStage from "./stages/web3/unavailable"
import { WhiteLabelAuthI } from "interfaces/store/auth"
import LoginKycStage from "./stages/kyc"
import LoginAccessDeniedStage from "./stages/access_denied"

interface WhiteLabelProperties {
  enabled: boolean
}

export interface LoginComponentProps {
  whitelabelAuth?: WhiteLabelAuthI
  loginHeaderLogo: string
}

const LoginContent = ({ loginHeaderLogo, whitelabelAuth }: LoginComponentProps) => {
  const { currentAuthStageShowing } = useAuthContext()

  const loginStages: AuthLoginStages = {
    login: {
      header: <LoginStageHeader logo={loginHeaderLogo} />,
      body: <LoginStageBody />,
      footer: null
    },
    otp_verification: {
      header: <LoginOtpStageHeader />,
      body: <LoginOtpStageBody />,
      footer: null
    },
    web3: {
      header: <LoginWeb3StageHeader />,
      body: <LoginWeb3StageBody />,
      footer: null
    },
    web3_unavailable: {
      header: null,
      body: <Web3IdleLoginStage />,
      footer: null
    },
    kyc: {
      body: <LoginKycStage />
    },
    access_denied: {
      body: <LoginAccessDeniedStage />
    }
  }

  const Header = loginStages[currentAuthStageShowing]?.header
  const Body = loginStages[currentAuthStageShowing]?.body
  const Footer = loginStages[currentAuthStageShowing]?.footer

  return (
    <LoginContentProvider whitelabelAuth={whitelabelAuth}>
      <div
        className={classNames("login-container w-100 h-100", {
          centralized: currentAuthStageShowing === AuthModalsEnum.OTP_VERIFICATION
        })}
      >
        <div
          className={classNames("login-content", {
            [currentAuthStageShowing]: currentAuthStageShowing === AuthModalsEnum.OTP_VERIFICATION
          })}
        >
          {Header}
          {Body}
        </div>
        {Footer}
      </div>
    </LoginContentProvider>
  )
}

export default LoginContent
