import { useState } from "react"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"

type FormValidateT = <t>(formData: t, field?: { [key: string]: any }) => t | null
type SubmitCallbackT = <t>(formData: t) => Promise<void>
export interface FormHookProps<t> {
  initialState: t
  submitCallback: SubmitCallbackT
  validate: FormValidateT
  failedSubmitCallback?: Function
}

export function useForm<t>({ initialState, submitCallback, validate, failedSubmitCallback }: FormHookProps<t>) {
  const { t } = useTranslation()
  const [formData, setFormData] = useState<t>(initialState)
  const [errors, setErrors] = useState<t>({} as t)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)

  const handleSingleValidate = (formData, name) => {
    const fieldError = validate(formData, name)
    if (Object.keys(fieldError).length === 0) {
      if (errors?.[name]) {
        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors }
          delete updatedErrors[name]
          return updatedErrors
        })
      }
    } else {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors, ...fieldError }
        return updatedErrors
      })
    }
  }

  const handleInputChange = (event: any) => {
    const { name, value } = event.target

    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: value }

      if (isSubmit) {
        handleSingleValidate(updatedFormData, name)
      }

      return updatedFormData
    })
  }

  const handleSubmit = async (event: any) => {
    event?.preventDefault()
    event?.stopPropagation()
    setIsFormLoading(true)

    const validationErrors = validate(formData)
    setErrors(validationErrors)

    const hasErrors = Object.values(validationErrors).some((error) => error)
    if (hasErrors) {
      setIsSubmit(true)
      setIsFormLoading(false)
      if (!!failedSubmitCallback) {
        toast.error(t("Fill_all_mandatory_fields"), { position: "top-center" })
        failedSubmitCallback()
      }
      return
    }

    try {
      await submitCallback(formData)
      setIsFormLoading(false)
    } catch (error) {
      console.log(error)
      setIsFormLoading(false)
    }
  }

  return {
    formData,
    errors,
    isFormLoading,
    handleInputChange,
    handleSubmit,
    setFormData,
    setErrors,
    setIsFormLoading,
    isSubmit,
    setIsSubmit
  }
}
