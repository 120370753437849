import BackButtonHandler from "@core/components/login/components/buttons/backButtonHandler"
import useKycContext from "../context/useKycContext"
import { KycStatusStageEnum } from "../context/KycContext"
import useFormContext from "utility/context/formContext/useFormContext"
import kycInitialValues from "../context/kycFormHooks/initialState"
import useAuthContext from "contexts/auth/AuthInContext"
import { AuthModalsEnum } from "contexts/auth/AuthContext"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import useEmailAuthContext from "contexts/auth/methods/email/useEmailAuthContext"
import useMarketPlaceAuthContext from "web-marketplace/provider/auth/useMarketPlaceAuthContext"
import { resetUserKycAndPermissionProcess } from "services/store"

type HandleBackByStageMap = {
  [key in Exclude<KycStatusStageEnum, KycStatusStageEnum.CONCLUDED>]: () => void
}

const KycBackButton = () => {
  const dispatch = useAppDispatch()
  const { setCurrentAuthStageShowing } = useAuthContext()
  const { handleAuthLogout } = useMarketPlaceAuthContext()
  const { resetEmailStep } = useEmailAuthContext()
  const { JWT } = useAppSelector((state) => state.auth)
  const { useKycSwiper, currentStatusStage, setCurrentStatusStage } = useKycContext()
  const { setFormData, formData } = useFormContext()

  const handleBackButton = () => {
    const handleBackByStageMap: HandleBackByStageMap = {
      [KycStatusStageEnum.START]: () => {
        resetEmailStep()
        handleAuthLogout()
        dispatch(resetUserKycAndPermissionProcess())
        setCurrentAuthStageShowing(AuthModalsEnum.LOGIN)
      },
      [KycStatusStageEnum.BASIC_INFOS]: () => {
        setFormData(kycInitialValues({ userData: JWT?.user, stage: "reset" }))
        setCurrentStatusStage(KycStatusStageEnum.START)
        useKycSwiper.slidePrev()
      }
    }

    const handleBack = handleBackByStageMap[currentStatusStage]
    if (handleBack) handleBack()
  }

  return (
    <>
      {currentStatusStage && currentStatusStage !== KycStatusStageEnum.CONCLUDED && (
        <div className="login-wrapper">
          <BackButtonHandler handleClickAction={() => handleBackButton()} />
        </div>
      )}
    </>
  )
}

export default KycBackButton
