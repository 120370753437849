import React from "react"
import DisplaySourceSkeleton from "./displaySkeleton"
import Icon from "@core/components/icon"
import { CiImageOff } from "react-icons/ci"

interface DisplayEmptySourceProps {}

const DisplayEmptySource: React.FC<DisplayEmptySourceProps> = ({}) => {
  return (
    <>
      <DisplaySourceSkeleton />
      <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center position-absolute">
        <Icon icon={CiImageOff} size={"2rem"} />
      </div>
    </>
  )
}

export default DisplayEmptySource
