import { Dispatch, SetStateAction, createContext } from "react"

export enum UnavailableContentEnum {
  marketplace = "marketplace",
  "marketplace-company" = "marketplace-company"
}

export interface UnavailableContentShieldStateI {
  isUnavailableContentShieldEnabled: boolean
  setIsUnavailableContentShieldEnabled: Dispatch<SetStateAction<boolean>>
}

export interface UnavailableContentContextI extends UnavailableContentShieldStateI {}

const UnavailableContentContext = createContext({} as UnavailableContentContextI)

export default UnavailableContentContext
