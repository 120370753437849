import DisplaySource from "components/displaySource"
import { useAuthContext } from "contexts/auth/AuthContext"
import React from "react"
import { Spinner } from "reactstrap"

export interface AuthButtonLogoProps {
  src: string
  authMethod: string
}

const AuthButtonLogo: React.FC<AuthButtonLogoProps> = ({ authMethod, src }) => {
  const { methodInProgress } = useAuthContext()

  return (
    <div className="d-flex position-relative">
      <DisplaySource src={src} size={{ maxWidth: "24px", minWidth: "24px" }} containerStyle={{}} />
      {authMethod === methodInProgress && (
        <Spinner
          color=""
          style={{
            position: "absolute",
            top: "-8px",
            left: "-8px",
            width: "40px",
            height: "40px"
          }}
        />
      )}
    </div>
  )
}

export default AuthButtonLogo
