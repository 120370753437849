import ExternalWallet from "@core/components/login/stages/login/externalWallet"
import SocialLogins from "./socialLogins"
import EmailLogin from "./emailLogin"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

const LoginStageBody = () => {
  const { t } = useTranslation()
  return (
    <div className="login-wrapper h-100 justify-content-between">
      <p className={classNames("text-center fs-6 m-0", {})}>{t("Text Modal Login")}</p>
      <div className="d-flex flex-column gap-1 justify-content-between h-100">
        <SocialLogins />
        <EmailLogin />
        <ExternalWallet />
      </div>
    </div>
  )
}

export default LoginStageBody
