import { createContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast, ToastPosition } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { removeSession } from "web-weispace/services/store"
import { removeSessionMetamask } from "web-weispace/services/store/metamask"
import { cookies } from "../cookies"
import { useAppDispatch } from "redux/hooks"
import { defaultWeispaceRoute } from "web-weispace/views"
import { useGlobalContext } from "../hooks/useContext"

const Context = createContext(null)

export const CookieExpiracyProvider = ({ children }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const data = cookies.get("userData")
  const navigate = useNavigate()
  const location = useLocation()
  const [didSetWeispaceRedirectUrl, setDidSetWeispaceRedirectUrl] = useState(false)
  const { setWeispaceRedirectUrl } = useGlobalContext()

  const weispaceLoginRoute = `${defaultWeispaceRoute}/login`

  const { from } = location.state || { from: { pathname: location?.pathname, search: location.search } }

  const showTokenExpiredToast = () => {
    toast.error(t("Token Expired Toast"), {
      position: process.env.REACT_APP_TOAST_POSITION as ToastPosition,
      duration: Number(process.env.REACT_APP_TOAST_DURATION),
      id: "token-expired-toast"
    })
  }

  useEffect(() => {
    const isLoginAfterLogout = from?.pathname === weispaceLoginRoute && from?.search === ""
    if (!didSetWeispaceRedirectUrl && !isLoginAfterLogout) {
      const { pathname, search: queryParams } = from || { pathname: defaultWeispaceRoute, search: "" }
      setWeispaceRedirectUrl(`${pathname}${queryParams}`)
      setDidSetWeispaceRedirectUrl(true)

      const urlParams = new URLSearchParams(from?.search)
      if (urlParams.has("redirectEndpoint")) {
        const redirectEndpoint = urlParams.get("redirectEndpoint")
        cookies.set("redirectEndpoint", redirectEndpoint)
      }
    }
  }, [from])

  useEffect(() => {
    if (data) {
      if (data.expires && new Date().toISOString() > data.expires) {
        console.log("No user data found", data)
        dispatch(removeSession({}))
        dispatch(removeSessionMetamask({}))
        navigate(weispaceLoginRoute)

        showTokenExpiredToast()
      }
    } else {
      console.log("No user data found", data)
      if (location.pathname !== weispaceLoginRoute) {
        navigate(weispaceLoginRoute)
        showTokenExpiredToast()
      }
    }
  }, [data])

  console.log(data)

  return <Context.Provider value={null}>{children}</Context.Provider>
}
