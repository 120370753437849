import { Dispatch, SetStateAction, createContext } from "react"

export enum Web3AuthProvidersEnum {
  METAMASK = "MetaMask"
}

export enum Web3AuthStatusEnum {
  Idle = "idle",
  Pending = "pending",
  Success = "success",
  Fail = "fail",
  Dismiss = "dismiss"
}

export type Web3AuthStatusState = {
  web3AuthStatus: Web3AuthStatusEnum
  setWeb3AuthStatus: Dispatch<SetStateAction<Web3AuthStatusEnum>>
}

export type Web3AuthLoadingState = {
  isWeb3AuthLoading: boolean
  setIsWeb3AuthLoading: Dispatch<SetStateAction<boolean>>
}

export type Web3AuthResultState = {
  web3AuthResult: any
  setWeb3AuthResult: Dispatch<SetStateAction<any>>
}

export type Web3SelectedAuthProviderState = {
  selectedWeb3AuthProvider: Web3AuthProvidersEnum
  setSelectedWeb3AuthProvider: Dispatch<SetStateAction<Web3AuthProvidersEnum>>
}

export enum Web3IdleStages {
  Connect = "connect",
  Get = "get",
  Get_Started_Extension = "get_started_extension",
  Get_Started_App = "get_started_app",
  Install = "install"
}

export type Web3IdleStageShowingState = {
  web3IdleStageShowing: Web3IdleStages
  setWeb3IdleStageShowing: Dispatch<SetStateAction<Web3IdleStages>>
}

export interface Web3AuthTreats
  extends Web3AuthLoadingState,
    Web3AuthResultState,
    Pick<Web3SelectedAuthProviderState, "selectedWeb3AuthProvider"> {}

export type Web3AuthProviderValues = {
  resetWeb3Auth: () => void
} & Web3AuthStatusState &
  Web3AuthLoadingState &
  Web3SelectedAuthProviderState &
  Web3AuthResultState &
  Web3IdleStageShowingState

const Web3AuthContext = createContext({} as Web3AuthProviderValues)

export default Web3AuthContext
