import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { axiosBase } from "../axiosbase"
import { cookies } from "../../utility/cookies"
import { getAnyTimeFromNow } from "utility/DateTimeUtils"

export const fetchJWTGoogle = createAsyncThunk("firebase/JWT/Google", async (body: object) => {
  try {
    const { data } = await axiosBase.post("/auth/google", body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchJWTFacebook = createAsyncThunk("firebase/JWT/Facebook", async (body: object) => {
  try {
    const { data } = await axiosBase.post("/auth/facebook", body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchJWTMicrosoft = createAsyncThunk("firebase/JWT/Microsoft", async (body: object) => {
  try {
    const { data } = await axiosBase.post("/auth/microsoft", body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchJWTApple = createAsyncThunk("firebase/JWT/Apple", async (body: object) => {
  try {
    const { data } = await axiosBase.post("/auth/apple", body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchAutoLogin = createAsyncThunk("auth/jwt", async (config: object) => {
  try {
    const { data } = await axiosBase.post("/login/jwt", {}, config)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})
const session = () => {
  const userData = cookies.get("userData")
  if (userData && userData.type !== "Metamask") {
    return userData
  }
  return null
}
export const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    JWT: null || session(),
    JWTError: null,
    JWTLoading: false
  },
  reducers: {
    removeSession: (state, action) => {
      state.JWTLoading = false
      state.JWT = null
      console.log("Removi padrão")
      cookies.remove("userData", { path: "/" })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJWTGoogle.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTGoogle.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        cookies.set("userData", { ...payload, expires: new Date(getAnyTimeFromNow()), type: "Google" }, { path: "/" })
      })
      .addCase(fetchJWTGoogle.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchJWTFacebook.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTFacebook.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        cookies.set(
          "userData",
          { ...payload, expires: new Date(getAnyTimeFromNow()), type: "Facebook" },
          { path: "/" }
        )
      })
      .addCase(fetchJWTFacebook.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchJWTMicrosoft.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTMicrosoft.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        cookies.set(
          "userData",
          { ...payload, expires: new Date(getAnyTimeFromNow()), type: "Microsoft" },
          { path: "/" }
        )
      })
      .addCase(fetchJWTMicrosoft.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchJWTApple.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTApple.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        cookies.set("userData", { ...payload, expires: new Date(getAnyTimeFromNow()), type: "Apple" }, { path: "/" })
      })
      .addCase(fetchJWTApple.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchAutoLogin.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchAutoLogin.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        cookies.set(
          "userData",
          { ...payload, expires: new Date(getAnyTimeFromNow()), type: "auto-login" },
          { path: "/" }
        )
      })
      .addCase(fetchAutoLogin.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })
  }
})

export const { removeSession } = authSlice.actions
export default authSlice.reducer
