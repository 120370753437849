import { useEffect, useState } from "react"
import AccessGuardContext, { AccessGuardContextI } from "./accessGuardContext"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { checkUserAccessPermission, setAuthIsExclusive, setUserAccessPermission } from "services/store"
import useAuthContext from "contexts/auth/AuthInContext"

interface AccessGuardProviderProps {
  children: React.ReactNode
}

//TODO - Somente entrar no guard após o carregamento do whiteLabelConfig

/**
 * Responsável por controlar quem pode ou não pode ver o conteúdo ( inviteOnly )
 */

const AccessGuardProvider = ({ children }: AccessGuardProviderProps) => {
  const { Provider } = AccessGuardContext
  const { isUserLoggedIn } = useAuthContext()
  const dispatch = useAppDispatch()
  const { whiteLabelGuard, whiteLabelGuardIsLoading } = useAppSelector((state) => state.whitelabelConfig)
  const { JWT, userHaveAccessPermission, authIsExclusive } = useAppSelector((state) => state.auth)
  const { collectionBySlug } = useAppSelector((state) => state.collectionBySlug)
  const { tokenBySlug } = useAppSelector((state) => state.tokenBySlug)

  const collectionId = collectionBySlug?.id || tokenBySlug?.collectionId

  const accessToken = JWT?.accessToken || null
  const userEmail = JWT?.user?.email

  const [isAccessGuardShieldEnabled, setIsAccessGuardShieldEnabled] = useState<boolean>(
    whiteLabelGuard?.private?.enabled
  )

  useEffect(() => {
    const isExclusiveAccess = whiteLabelGuard?.private?.enabled
    console.log("AccessGuardProvider => Page is exclusive?", isExclusiveAccess)
    if (isExclusiveAccess) {
      setIsAccessGuardShieldEnabled(isExclusiveAccess)
      dispatch(setAuthIsExclusive(true))
      console.log(JWT)
      //TODO
      console.log(isUserLoggedIn, "isUserLoggedIn")
      if (!isUserLoggedIn) {
        dispatch(setUserAccessPermission(false))
      }
    } else {
      dispatch(setUserAccessPermission(true))
    }
  }, [whiteLabelGuardIsLoading])

  useEffect(() => {
    if (isAccessGuardShieldEnabled && JWT?.accessToken && collectionId && userEmail) {
      console.log("AccessGuardProvider => User is logged in, checking access permission")
      dispatch(checkUserAccessPermission({ accessToken, collectionSlug: collectionId, userEmail }))
    } else if (authIsExclusive && !isAccessGuardShieldEnabled && !JWT?.accessToken) {
      console.log("AccessGuardProvider => User is not logged in, redirecting to login page")
      setIsAccessGuardShieldEnabled(true)
      setUserAccessPermission(false)
    }
  }, [isAccessGuardShieldEnabled, JWT, collectionId, userEmail])

  useEffect(() => {
    console.log("AccessGuardProvider => ToggleShieldEffect - Effect")
    if (userHaveAccessPermission) {
      console.log("AccessGuardProvider => ToggleShieldEffect toggle to False")
      setIsAccessGuardShieldEnabled(false)
    }
  }, [userHaveAccessPermission])

  const providerValues: AccessGuardContextI = {
    isAccessGuardShieldEnabled,
    setIsAccessGuardShieldEnabled,
    userHaveAccessPermission
  }

  return <Provider value={providerValues}>{children}</Provider>
}

export default AccessGuardProvider
