import { createContext, useEffect } from "react"
import { toast, ToastPosition } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { removeSession } from "services/store"
import { removeSessionMetamask } from "services/store/metamask"
import { cookies } from "utility/cookies"
import { useAppDispatch } from "redux/hooks"

const Context = createContext(null)

export const CookieExpiracyProvider = ({ children }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const data = cookies.get("userData")
  
  useEffect(() => {
    if (data && data.expires && new Date().toISOString() > data.expires) {
      console.log("Passei e removi a sessão")
      dispatch(removeSession({}))
      dispatch(removeSessionMetamask({}))

      toast.error("Acesso expirado, faça login novamente!", {
        position: process.env.REACT_APP_TOAST_POSITION as ToastPosition,
        duration: Number(process.env.REACT_APP_TOAST_DURATION)
      })
    }
  }, [data])

  return <Context.Provider value={null}>{children}</Context.Provider>
}
