import Web3IdleDesktopGet from "./desktop"

const Web3IdleGet = () => {
  return (
    <div className="login-wrapper h-100 justify-content-center">
      <Web3IdleDesktopGet />
    </div>
  )
}

export default Web3IdleGet