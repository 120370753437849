import { useAddLayoutToRoute } from "../utility/hooks/useAddLayoutToRoute"
import benefitsRoutes from "./benefits/router"
import challengesRoutes from "./challenges/router"
import faqRoutes from "./faq/router"
import { homeRoutes } from "./home/router"
import { TokenRoutes } from "./my-nfts/router"
import perfilRoutes from "./perfil-component/router"
import productsRoutes from "./products/router"
import transactionsRoutes from "./purshased-history/router"
import contentsRoutes from "./contents/router"
export const defaultWeispaceRoute = "/weispace"

const weispaceRoutes = [
  ...homeRoutes,
  ...benefitsRoutes,
  ...faqRoutes,
  ...productsRoutes,
  ...TokenRoutes,
  ...perfilRoutes,
  ...transactionsRoutes,
  ...challengesRoutes,
  ...contentsRoutes
]

export default useAddLayoutToRoute(weispaceRoutes)
