import classNames from "classnames"
import { DisplaySourceTypeProps } from "."
import { getIpfsImageUrl } from "utility/ipfs"

interface DisplaySourceImageProps extends DisplaySourceTypeProps {}

const DisplaySourceImage = ({ src, displayClassName, displayStyle }: DisplaySourceImageProps) => {
  return (
    <div
      className={classNames("display-source-image", { [displayClassName]: displayClassName })}
      style={{
        backgroundImage: `url(${getIpfsImageUrl(src, 1024)})`,
        ...displayStyle
      }}
    />
  )
}

export default DisplaySourceImage
