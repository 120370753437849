import { createAsyncThunk } from "@reduxjs/toolkit"

import { getNFTBenefits } from "../getNFTBenefits"
import { axiosBase } from "../axiosbase"

import { CreatedTokenWithBenefits, FetchTokensProps } from "./types"

export const fetchTokens = createAsyncThunk<CreatedTokenWithBenefits[], FetchTokensProps>(
  "tokens/fetchTokens",
  async ({ accessToken, userId }: FetchTokensProps) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }

    return await axiosBase.get<CreatedTokenWithBenefits[]>("/tokens/owned?limit=0", config).then(async (response) => {
      const nfts = response.data
      const nftsWithBenefits = await Promise.all(
        nfts.map(async (nft) => {
          const response = await getNFTBenefits(nft.id)

          const userHoldings = nft.owners.filter((owner) => owner.userId === userId)
          const totalUserQuantity = userHoldings.reduce((acc, owner) => acc + Number(owner.quantity), 0)

          return { ...nft, benefits: response.data, totalUserQuantity }
        })
      )
      return nftsWithBenefits
    })
  }
)
