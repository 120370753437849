import MarketPlaceGuardsProviders from "./providers"
import MarketPlaceGuardGate from "./gate"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { useLocation } from "react-router-dom"
import { TableRouteIdentifierEnum, fetchWhiteLabelGuardData, setWhiteLabelGuardIsLoading } from "redux/whitelabelConfig"
import { Suspense, useEffect, useState } from "react"
import { Spinner } from "reactstrap"
import { fetchCollectionsById } from "services/store/tokenOffer"
import { getCollectionBySlug, getTokenBySlug } from "services/slugs/slugsActions"
import { getAnyTimeFromNow } from "utility/DateTimeUtils"
import { cookies } from "utility/cookies"

enum MarketPlaceParamsEnum {
  token = "token",
  collection = "collection",
  marketplace = "marketplace",
  "marketplace-company" = "marketplace-company"
}

type ParamsGuardRequestMaps = {
  [key in MarketPlaceParamsEnum]: Function
}

interface MarketPlaceGuardsProviderProps {
  children: React.ReactNode
}

const MarketPlaceGuardProvider = ({ children }: MarketPlaceGuardsProviderProps) => {
  const dispatch = useAppDispatch()

  const { JWT } = useAppSelector((state) => state.auth)
  const { whiteLabelGuardIsComplete } = useAppSelector((state) => state.whitelabelConfig)
  const [continueFetching, setContinueFetching] = useState<boolean>(true)

  const location = useLocation()
  const params = location?.pathname.split("/")
  //TODO Mover para uma logistica de rotas
  const guardWhiteLabelRequestByParamMap: ParamsGuardRequestMaps = {
    [MarketPlaceParamsEnum.token]: () => {
      dispatch(fetchWhiteLabelGuardData({ slug: params[2], tableIdentifier: TableRouteIdentifierEnum.TOKEN }))
      dispatch(getTokenBySlug(params[2]))
    },
    [MarketPlaceParamsEnum.collection]: () => {
      dispatch(fetchWhiteLabelGuardData({ slug: params[2], tableIdentifier: TableRouteIdentifierEnum.COLLECTION }))
      dispatch(getCollectionBySlug(params[2]))
    },
    [MarketPlaceParamsEnum.marketplace]: () => {
      dispatch(fetchWhiteLabelGuardData({ slug: params[2], tableIdentifier: TableRouteIdentifierEnum.COMMUNITY }))
    },
    [MarketPlaceParamsEnum["marketplace-company"]]: () => {
      dispatch(fetchWhiteLabelGuardData({ slug: params[2], tableIdentifier: TableRouteIdentifierEnum.COMPANY }))
    }
  }

  useEffect(() => {
    const getWhiteLabelGuard = async () => {
      const guardCallback = guardWhiteLabelRequestByParamMap?.[params[1]]
      if (guardCallback) {
        await guardCallback()
        setContinueFetching(false)
      } else {
        dispatch(setWhiteLabelGuardIsLoading(false))
      }
    }

    if (params && continueFetching) {
      getWhiteLabelGuard()
    }
  }, [params, continueFetching])

  return (
    <MarketPlaceGuardsProviders>
      <MarketPlaceGuardGate>
        <Suspense
          fallback={
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
              <Spinner color="primary" />
            </div>
          }
        >
          {children}
        </Suspense>
      </MarketPlaceGuardGate>
    </MarketPlaceGuardsProviders>
  )
}

export default MarketPlaceGuardProvider
