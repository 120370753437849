import { AgeGateContext } from "contexts/AgeGateContext"
import AccessGuardProvider from "web-marketplace/contexts/accessGuard/accessGuardProvider"
import AuthGuardProvider from "web-marketplace/contexts/authGuard/authGuardProvider"
import UnavailableContentGuardProvider from "web-marketplace/contexts/unavailableContentGuard/unavailableContentGuardProvider"

interface MarketPlaceGuardsProviderProps {
  children: React.ReactNode
}

const MarketPlaceGuardsProviders = ({ children }: MarketPlaceGuardsProviderProps) => {
  return (
    <AccessGuardProvider>
      <UnavailableContentGuardProvider>
        <AuthGuardProvider>
          <AgeGateContext>{children}</AgeGateContext>
        </AuthGuardProvider>
      </UnavailableContentGuardProvider>
    </AccessGuardProvider>
  )
}

export default MarketPlaceGuardsProviders
