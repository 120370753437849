import ButtonHandler from "@core/components/login/components/buttons/buttonHandler"
import useEmailAuthContext from "contexts/auth/methods/email/useEmailAuthContext"
import { useAppSelector } from "redux/hooks"

const OtpSubmitButton = () => {
  const {
    isEmailAuthLoading,
    formData: { otpCode }
  } = useEmailAuthContext()
  const { authIsExclusive, userAccessPermissionIsLoading } = useAppSelector((state) => state.auth)

  const isLoading = isEmailAuthLoading || (authIsExclusive && userAccessPermissionIsLoading)
  const isDisabled = otpCode?.length !== 6 || isLoading

  return (
    <>
      <ButtonHandler
        id="buttonHandler-Send"
        type="submit"
        text="Verify_and_Login"
        disabled={isDisabled}
        isLoading={isLoading}
      />
    </>
  )
}

export default OtpSubmitButton
