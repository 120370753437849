import { axiosBase } from "../axiosBase"
import { createAsyncThunk } from "@reduxjs/toolkit"

import { CreatedCompany } from "interfaces/company"
import { CreatedCollection } from "interfaces/collection"
import { CreatedCommunity } from "utility/request-interfaces/community-types"
import { CreatedToken } from "utility/request-interfaces/collection-types"

export const getCompanyBySlug = createAsyncThunk<CreatedCompany, string>("company/getBySlug", async (slug: string) => {
  try {
    return (await axiosBase.get<CreatedCompany>(`/companies/slug/${slug}`))?.data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const getCommunityBySlug = createAsyncThunk<CreatedCommunity, string>(
  "community/getBySlug",
  async (slug: string) => {
    try {
      return (await axiosBase.get<CreatedCommunity>(`/communities/slug/${slug}`))?.data
    } catch (error) {
      throw new Error(error.response.data)
    }
  }
)

export const getCollectionBySlug = createAsyncThunk<CreatedCollection, string>(
  "collection/getBySlug",
  async (slug: string) => {
    try {
      return (await axiosBase.get<CreatedCollection>(`/collections/slug/${slug}`))?.data
    } catch (error) {
      throw new Error(error.response.data)
    }
  }
)

export const getTokenBySlug = createAsyncThunk<CreatedToken, string>("token/getBySlug", async (slug: string) => {
  try {
    return (await axiosBase.get<CreatedToken>(`/tokens/slug/${slug}`))?.data
  } catch (error) {
    throw new Error(error.response.data)
  }
})
