import { UserKyc } from "interfaces/user"
import { useEffect } from "react"
import useFormContext from "utility/context/formContext/useFormContext"

interface KycBasicInfosFormContainerProps {
  children?: React.ReactNode
  mainKycForm?: UserKyc
  mainSubmitCallback?: Function
}

const KycBasicInfosFormContainer = ({ children, mainKycForm, mainSubmitCallback }: KycBasicInfosFormContainerProps) => {
  const { handleSubmit, errors } = useFormContext()

  // useEffect(() => {
  //   console.log("hi")
  //   console.log(Object?.keys(errors)?.length)
  //   if (
  //     mainKycForm?.email &&
  //     mainKycForm?.fullName &&
  //     mainKycForm?.phone &&
  //     mainKycForm?.identityDocument &&
  //     Object.keys(errors).length === 0
  //   ) {
  //     mainSubmitCallback(null)
  //   }
  // }, [mainKycForm, errors])

  return (
    <form
      onSubmit={handleSubmit}
      className="d-flex flex-column gap-1 justify-content-center h-100 login-wrapper justify-content-between"
    >
      {children}
    </form>
  )
}

export default KycBasicInfosFormContainer
