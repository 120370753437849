import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "axios"
import { cookies } from "utility/cookies"
import { getAnyTimeFromNow } from "utility/DateTimeUtils"
import { axiosBase } from "services/axiosBase"

const currentParams = window.location.pathname.split("/")[1]
const defaultWeispaceRoute = "/weispace"

export const fetchJWTMetamask = createAsyncThunk(
  "metamask/JWT",
  async (body: {
    email?: string
    address: string
    network: string
    blockchain: string
    signature: string
    message: string
  }) => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/metamask`, body)
    return data
  }
)

export const patchUserEmailMetamask = createAsyncThunk(
  "metamask/patchUserEmail",
  async ({ email, accessToken }: any) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/users/me`,
      { email },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    return data
  }
)

export const fetchAutoLoginFromMetamask = createAsyncThunk("auth/jwt", async (config: object) => {
  try {
    const { data } = await axiosBase.post("/login/jwt", {}, config)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

const session = () => {
  const res = cookies.get("userData")
  if (res?.type === "Metamask") {
    return res
  }
  return null
}

export const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    JWT: session() || null,
    JWTError: null,
    JWTLoading: false,
    shouldUserAddEmail: session()?.user?.email ? false : true || true,
    emailError: null
  },
  reducers: {
    removeSessionMetamask: (state, action) => {
      state.JWTLoading = false
      state.JWT = null
      console.log("Removi, via metamask")
      cookies.remove("userData", { path: "/" })
      // cookies.remove("userData", { path: "/", domain: process.env.REACT_APP_COOKIE_DOMAIN })
    },
    setShouldUserAddEmail: (state, { payload }) => {
      state.shouldUserAddEmail = payload
    },
    setEmailError: (state, { payload }) => {
      state.emailError = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJWTMetamask.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTMetamask.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        if (payload.user.email && !payload.user.email.includes("privaterelay.appleid.com")) {
          state.shouldUserAddEmail = false
        } else {
          state.shouldUserAddEmail = true
        }

        cookies.set(
          "userData",
          { ...payload, expires: new Date(getAnyTimeFromNow()), type: "Metamask" },
          { path: "/" }
          // { path: "/", domain: process.env.REACT_APP_COOKIE_DOMAIN }
        )
        if (`/${currentParams}` === defaultWeispaceRoute) {
          window.location.reload()
        }
      })
      .addCase(fetchJWTMetamask.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(patchUserEmailMetamask.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(patchUserEmailMetamask.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.shouldUserAddEmail = false
        state.emailError = null
      })
      .addCase(patchUserEmailMetamask.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.emailError = "Invalid_or_already_in_use_email"
        state.JWTError = payload
      })
      .addCase(fetchAutoLoginFromMetamask.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchAutoLoginFromMetamask.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        cookies.set(
          "userData",
          { ...payload, expires: new Date(getAnyTimeFromNow()), type: "auto-login" },
          { path: "/" }
        )
      })
      .addCase(fetchAutoLoginFromMetamask.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })
  }
})
export const { removeSessionMetamask, setShouldUserAddEmail, setEmailError } = authSlice.actions
export default authSlice.reducer
