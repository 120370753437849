import { lazy } from "react"

const LazyChallenges = lazy(() => import("./pages/challenges-home"))

const mainChallengesPath = "/weispace/challenges"

export const challengesPaths = {
  default: mainChallengesPath,
  home: `${mainChallengesPath}/home`
}

const challengesRoutes = [
  {
    path: challengesPaths.home,
    element: <LazyChallenges />
  }
]

export default challengesRoutes
