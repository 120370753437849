import { axiosBase } from "./axiosBase"

export const benefitValidatorLogin = async (data: { email: string; password: string }, companyId: string) => {
  return await axiosBase.post(`/auth/benefit-validator/company/${companyId}`, data)
}

export const validateUserEmailAndSendCode = async (data: { email: string }, network: string, company: string) => {
  return await axiosBase.post(`/auth/email?network=${network}&company=${company}`, data)
}

export const validateUserCode = async (data: { email: string; code: string }) => {
  return await axiosBase.post(`/auth/email/confirm`, data)
}
