import { useEffect, useState } from "react"
import AuthContext, { AuthMethods, AuthMethods2, AuthModalsEnum } from "./AuthContext"
import useFirebaseAuth from "./hooks/useFirebaseAuth"
import useAuthNetwork from "./hooks/useAuthNetwork"
import useMetamaskAuth from "./hooks/useMetamaskAuth"
import useWagmi from "./hooks/useWagmi"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { setShowLoginModal } from "@core/components/login-modal/store"

interface AuthProviderProps {
  children: React.ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { Provider } = AuthContext
  const dispatch = useAppDispatch()

  const { userHaveAccessPermission } = useAppSelector((state) => state.auth)
  const { shouldUserDoKyc, userKycStatus } = useAppSelector((state) => state.auth)

  // Modal state
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false)
  //TODO Revert to default State
  const [currentAuthStageShowing, setCurrentAuthStageShowing] = useState<AuthModalsEnum>(AuthModalsEnum.LOGIN)

  // Auth State
  //TODO Thats gonna be the v2 from the auth methods.
  const [currentMethodInProgress, setCurrentMethodInProgress] = useState<AuthMethods2>(null)

  const [methodInProgress, setMethodInProgress] = useState<AuthMethods>(null)

  //TODO A better way to handle this.
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false)
  const { JWT } = useAppSelector((state) => state.auth)

  // Global Web3 Requirements
  const { networkMap, expectedChain, expectedChainName } = useAuthNetwork()
  const wagmiConfigs = useWagmi({ expectedChain })

  //Global Platform Logistic
  const [voucherCode, setVoucherCode] = useState<string>("")

  //TODO Need to remove.
  const { handleFirebaseAuth, isFirebaseAuthLoading, setIsFirebaseAuthLoading } = useFirebaseAuth({
    networkMap,
    setIsAuthModalOpen,
    setIsUserLoggedIn,
    setMethodInProgress
  })
  const {
    metamaskAuthRequest,
    metamaskAuthStatus,
    isMetamaskAuthLoading,
    setIsMetamaskAuthLoading,
    resetMetamaskAuth
  } = useMetamaskAuth({
    expectedChain,
    networkMap,
    setIsUserLoggedIn,
    setCurrentAuthStageShowing,
    wagmiConfigs
  })
  const {
    network: { chain }
  } = wagmiConfigs

  useEffect(() => {
    if (!isUserLoggedIn) {
      setCurrentAuthStageShowing(AuthModalsEnum.LOGIN)
    }
  }, [isUserLoggedIn])

  //CADA LOGIN TER SEU TRATAMENTO

  const handleLoginSuccess = () => {
    setIsFirebaseAuthLoading(false)
    setIsMetamaskAuthLoading(false)
  }

  // useEffect(() => {
  //   const autoLoginByJWT = async () => {
  //     const urlParams = new URLSearchParams(window.location.search)
  //     if (urlParams.get("header")) {
  //       setIsOAuthLoading((prev) => !prev)
  //       const config = getAxiosConfig(
  //         `${urlParams.get("header")}.${urlParams.get("payload")}.${urlParams.get("signature")}`
  //       )
  //       // if (urlParams.get("metamask")) {
  //       //   dispatch(fetchAutoLoginFromMetamask(config))
  //       // } else {
  //       //   dispatch(fetchAutoLogin(config))
  //       // }
  //       setIsUserLoggedIn(true)
  //       setIsOAuthLoading((prev) => !prev)
  //     }
  //   }

  //   autoLoginByJWT()
  // }, [])
  const providerValues = {
    handleFirebaseAuth,
    isFirebaseAuthLoading,
    setIsFirebaseAuthLoading,
    isMetamaskAuthLoading,
    setIsMetamaskAuthLoading,
    isAuthModalOpen,
    setIsAuthModalOpen,
    networkMap,
    expectedChain,
    expectedChainName,
    metamaskAuthRequest,
    chain,
    metamaskAuthStatus,
    isUserLoggedIn,
    setIsUserLoggedIn,
    voucherCode,
    setVoucherCode,
    currentAuthStageShowing,
    setCurrentAuthStageShowing,
    methodInProgress,
    resetMetamaskAuth,
    wagmiConfigs,
    handleLoginSuccess
  }

  return <Provider value={providerValues}>{children}</Provider>
}

export default AuthProvider
