import DisplaySource from "components/displaySource"

interface WeispaceLoginBannerImageProps {
  src: string
}

const WeispaceLoginBannerImage = ({ src }: WeispaceLoginBannerImageProps) => {
  return (
    <>
      <DisplaySource src={src} />
    </>
  )
}

export default WeispaceLoginBannerImage
